import React, {useRef} from 'react';
import {
    faArrowLeft,
    faArrowRight,
    faQuoteLeft,
    faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "react-bootstrap";

function ProjectTemplateOne({data, setSubPage, useNextAndPrevBtns = false}) {
    const scrollRef = useRef(null)

    const executeScroll = () => scrollRef.current.scrollIntoView()


    return <>
        {data &&
        <div ref={scrollRef} className={"position-relative"} style={{margin: "-100vh 0 0 0", scrollBehavior: "smooth"}}>
            <div className="sub-page__img"
                 style={{
                     backgroundImage: `url(${data.img})`,
                     marginLeft: "0",
                     backgroundPositionX: data.imgAlignX,
                     backgroundPositionY: data.imgAlignY,
                 }}/>

            <div className={"container"}>
                <div className={"sub-head"}>
                    <div className={"sub-head__text"}>
                        <h1>{data.title}</h1>
                        <p><FontAwesomeIcon icon={faQuoteLeft}/> {data.quote} <FontAwesomeIcon icon={faQuoteRight}/></p>
                    </div>
                    {/*<a onClick={() => scrollRef.current.scrollIntoView()}><FontAwesomeIcon icon={faChevronDown}/></a>*/}
                </div>
                <div className={"sub-vid"}>
                    <iframe className={"sub-vid__iframe"} src={data.vid} frameBorder={"0"} allowFullScreen
                            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}/>
                </div>

                {/*Prev / Next*/}
                {useNextAndPrevBtns &&
                <PrevNextLinks scrollUpHandler={executeScroll} data={data} setSubPage={setSubPage}/>}


                {/* For two column credits: */}
                {(data.li1 || data.li2) &&
                <Row>
                    {data.li1 &&
                    <Col className={"sub-credits"}>
                        <ul>{data.li1}</ul>
                    </Col>}

                    {data.li2 &&
                    <Col className={"sub-credits col-1-of-2"}>
                        <ul>{data.li2}</ul>
                    </Col>}
                </Row>}

                {/* For centered credit*/}
                {data.credCentered &&
                <div className={"sub-credits float-none text-center"}>{data.credCentered}</div>
                }


                <div className={"sub-paragraphs"}>{data.description}</div>

                {/*Prev / Next*/}
                {useNextAndPrevBtns &&
                <PrevNextLinks scrollUpHandler={executeScroll} data={data} setSubPage={setSubPage}/>}
                <div className={"py-4"}/>
            </div>
        </div>}
    </>
}

export default ProjectTemplateOne;


function PrevNextLinks({data, setSubPage, scrollUpHandler}) {
    return <>
        <Row className={"sub__next-prev u-mar-top-2"}>
            <Col className={"sub__prev"}>
                {data.prev.id !== null &&
                <p className={"sub__next-prev--btn"} onClick={() => {
                    setSubPage(data.prev.id)
                    scrollUpHandler()
                }}>
                    <FontAwesomeIcon icon={faArrowLeft}/> Previous
                    <strong style={{filter: "brightness(108%)"}}> {data.prev.title}</strong>
                </p>}
            </Col>
            <Col className={"sub__next"} onClick={() => {
                setSubPage(data.next.id)
                scrollUpHandler()
            }}>
                {data.next.id !== null &&
                <p className={"sub__next-prev--btn"}>
                    <strong style={{filter: "brightness(108%)"}}>{data.next.title} </strong>Next
                    <FontAwesomeIcon icon={faArrowRight}/>
                </p>}
            </Col>
        </Row>
    </>
}

