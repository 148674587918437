import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import SiteProvider from "./providers/SiteProvider";
import {BrowserRouter as Router} from "react-router-dom";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
  <Router>
    <SiteProvider>
      <App />
    </SiteProvider>
  </Router>
);


