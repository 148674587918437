import React from "react";
import {Col, Row} from "react-bootstrap";
import HtmlReader from "./HtmlReader";

function HomeIntro({intro}) {
  const x = !!intro && intro[0]?.homeIntro
  return (
    <div className={"container overflow-hidden"}>
      <Row>
        <Col md={4} lg={3} className={"d-flex"}>
          <div className={"d-inline"}>
            <img src={x.img} alt={x.alt} className={"home-intro-img w-100"} />
            <div className={"text-stretch home-intro-img"}>
              {x?.caption?.split("").map((l, i) => {
                return <span className={"default-font-styles font-colour"} key={i}>{l}</span>
              })}
            </div>
          </div>
        </Col>
        <Col md={8} lg={9} className={"mt-4 mt-md-0"}>
          <HtmlReader htmlContent={x.text} classNames={"mb-0"} />
        </Col>
      </Row>
    </div>
  );
}

export default HomeIntro;
