import React, {useState, useEffect, useContext} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {STRIPE_PUBLIC_LIVE_KEY, STRIPE_PUBLIC_TEST_KEY} from "../config";
import P3_PaymentIntentCheckoutForm
  from "../components/callToAction/donorForm/donorForm_P3/P3_PaymentIntent-CheckoutForm";
import P3_SetupIntentCheckoutForm
  from "../components/callToAction/donorForm/donorForm_P3/P3_SetupIntentIntent-CheckoutForm";
import {db, functions} from "../dataManagement/firebase/firebaseConfig";
import {httpsCallable} from "firebase/functions";
import {DonorFormContext} from "./DonorFormContext";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
let STRIPE_KEY = STRIPE_PUBLIC_LIVE_KEY
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  STRIPE_KEY = STRIPE_PUBLIC_TEST_KEY
}
const stripePromise = loadStripe(STRIPE_KEY);

export default function StripePaymentProvider({setPageNum}) {
  const {form, customer, setCustomer} = useContext(DonorFormContext)
  const [clientSecret, setClientSecret] = useState("");
  const [dbAuthCode, setDbAuthCode] = useState(null);
  const [hasBeenLogged, setHasBeenLogged] = useState(false);

  useEffect(() => {
    if (customer && customer.id && dbAuthCode && !hasBeenLogged) {
      db.collection("supporter_logs").doc(customer.id)
        .set({
          [form.formCreated_UTC]: {
            form,
            customer,
            dbAuthCode,
            paid: false,
            triedPaying: false,
            shownConfirmation: false
          }
        }, {merge: true})
        .then(() => setHasBeenLogged(true))
    }
  }, [customer, dbAuthCode])


  // CREATE PAYMENT/SETUP INTENT
  useEffect(() => {
    if (form.selectedAmount >= 100 || form.customAmount >= 100) {
      // Sort form data before sending to server
      const {selectedAmount, customAmount, ...wantedData} = form
      const finalWantedData = {
        ...wantedData,
        amount: form.customAmountChecked ? form.customAmount : form.selectedAmount
      }
      // Create payment intent
      const createIntent = httpsCallable(functions, "prepNewPayment")
      createIntent(finalWantedData)
        .then(({data}) => {
          if (data.status === 200) {
            // console.log("SUCCESSFUL PAYMENT/SETUP INTENT!: ", data)
            setDbAuthCode(data.dbAuthCode)
            setClientSecret(data.payload.client_secret)
            setCustomer(data.customer)
          }
        })
    }
  }, []);

  const appearance = {
    // theme: 'flat',
    variables: {
      fontFamily: " \"Roboto\", sans-serif",
      fontLineHeight: "1.5",
      borderRadius: "0px",
      colorBackground: "#fff",
      colorText: "#6c757d",
      colorDanger: "#dc3545",
      colorPrimary: "#ff9605",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",

      },
      ".Input": {
        border: "solid 1px #bababa",
        padding: "6px 8px",
        lineHeight: "1.9",
        boxShadow: "none",

      },
      ".Input:focus": {
        border: "solid 1px #ff9605",
        boxShadow: "none",
        padding: "6px 8px",
        lineHeight: "1.9",

      },
      ".Input::placeholder": {
        color: "#a1a1a1",
        opacity: ".8",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray"
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "solid 1px #bababa",
      },
      ".Tab:hover": {
        border: "solid 1px #ff9605",
        boxShadow: "none",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover, .Tab:focus": {
        border: "solid 1px #ff9605",
        boxShadow: "none",
        backgroundColor: "#fff",
        color: "#6c757d",
      },
      ".p-TabIcon--selected": {
        fill: "#6c757d",
      },
      ".Label": {
        fontWeight: "300"
      }
    }
  };


  const elOptions = {
    clientSecret,
    fonts: [{cssSrc: "https://fonts.googleapis.com/css?family=Roboto"}],
    appearance,
  };

  return (
    <div className="donor-form__el-wrapper">
      {!clientSecret &&
      <div style={{minHeight: "20rem"}} className={"d-flex justify-content-center p-5"}><p style={{opacity: ".2"}}>One
        sec...</p></div>}
      {clientSecret && (
        <Elements options={elOptions} stripe={stripePromise}>
          {form.frequency === "ONE_TIME" ?
           <P3_PaymentIntentCheckoutForm setPageNum={setPageNum} dbAuthCode={dbAuthCode} /> :
           <P3_SetupIntentCheckoutForm setPageNum={setPageNum} dbAuthCode={dbAuthCode} />}
        </Elements>
      )}
    </div>
  )
    ;
}
