import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as solidHeart} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-regular-svg-icons"
import {updateData} from "../../../dataManagement/firebase/dbFunctions";
import {useCookies} from 'react-cookie';


function Likes({likes, postId}) {
    const [cookies, setCookie] = useCookies(["sc_liked_posts"]);
    const [liked, setLiked] = useState(null)

    // console.log("LIKED??? ", cookies["sc_liked_posts"][postId])
    useEffect(() => {
        cookies && cookies["sc_liked_posts"] && setLiked(cookies["sc_liked_posts"][postId])
    }, [cookies && cookies["sc_liked_posts"] && cookies["sc_liked_posts"][postId]])

    const handleLike = () => {
        setLiked(!liked)
        let c
        if (cookies.sc_liked_posts) {
            let tempObj = cookies["sc_liked_posts"]
            tempObj[postId] = !liked
            c = tempObj
        } else {
            c = {[postId]: !liked}
        }
        setCookie("sc_liked_posts", c, {expires: (d => new Date(d.getFullYear() + 2, d.getMonth(), d.getDate()))(new Date())});
        updateData("likes", !liked ? likes + 1 : likes - 1, postId, "likesComments")
    }

    return <>
        <div className={"scSocial__likes"}>
            <FontAwesomeIcon icon={liked ? solidHeart : faHeart}
                             className={"scSocial__likes--likeBtn " + (liked ? "prime-colour" : "font-colour")}
                             onClick={handleLike} style={{cursor: "pointer"}}/>
            <p id="likeInt" className={""}>{likes}</p>
        </div>
    </>
}

export default Likes;
