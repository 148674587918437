import React from 'react';

function Funders({funders, dark}) {

    return <>
        <div className="pt-2 pt-md-3 pb-3">
            <div className={"d-flex justify-content-center justify-content-md-start flex-wrap"}
                 style={{maxHeight: dark ? "0" : "100vw", overflow: "hidden"}}>
                {funders && funders.map((f, i) => {
                    return <span key={i}>
                        {f.link !== "" ?
                            <a key={i} href={f.link} target="_blank" rel={"noreferrer"}>
                                <img className="associateLogos" src={f.logo} alt={f.name} />
                            </a>
                            :
                            <img className="associateLogos" src={f.logo} alt={f.name} />}
                    </span>
                })}
            </div>
            <div className={"d-flex justify-content-center justify-content-md-start flex-wrap"}
                 style={{maxHeight: !dark ? "0" : "100vw", overflow: "hidden"}}>
                {funders && funders.map((f, i) => {
                    return <span key={i}>
                        {f.link !== "" ?
                            <a key={i} href={f.link} target="_blank" rel={"noreferrer"}>
                                <img className="associateLogos associateLogos__dark" src={f.logoDark} alt={f.name} />
                            </a>
                            :
                            <img className="associateLogos associateLogos__dark" src={f.logoDark} alt={f.name} />}
                    </span>
                })}
            </div>

        </div>
    </>
}

export default Funders;