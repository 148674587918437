import React from 'react';

function LockdownSubPageCards({setSubPage, handleScrollLock}) {

    const handleOpen = (subPageCode) => {
        handleScrollLock("lock")
        setSubPage(subPageCode)
    }

    return (
        <div className={"ldCredits m-auto"} style={{maxWidth: "1250px"}}>
            <div className={"sc-pageContainer ldCredits__cardWrap"}>

                <a className={"ldCredits__card ld-credit-1"} onClick={() => {
                    handleOpen("f")
                }}>
                          <span>
                            <img
                                src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FFrontline%20Worker.jpg?alt=media&token=33d59bd7-6ede-4429-8eef-b3ab9fe9b94c"}
                                alt="Frontline Worker"/>
                            <h5>The Frontline Worker</h5>
                            <h6>by Susannah Self and Kamal Kaan</h6>
                          </span>
                </a>

                <a className={"ldCredits__card"} onClick={() => handleOpen("a")}>
                          <span>
                            <img
                                src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FAnti-masker.jpg?alt=media&token=f1547279-2333-4287-8e78-3ede9ea2c8f6"}
                                alt={"Anti-masker"}/>
                            <h5>The Anti-Masker</h5>
                            <h6>by Nicholas Olsen and Kamal Kaan</h6>
                          </span>
                </a>

                <a className={"ldCredits__card"} onClick={() => handleOpen("s")}>
                          <span>
                            <img
                                src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FSurvivor.jpg?alt=media&token=cdd27c30-f568-41c7-bc9b-dfd19ac7cf5e"}
                                alt={"Survivor"}/>
                            <h5>The Survivor</h5>
                            <h6>by Jake Adams and Kamal Kaan</h6>
                          </span>
                </a>

                <a className={"ldCredits__card"} onClick={() => handleOpen("d")}>
                          <span>
                            <img
                                src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FDeath.jpg?alt=media&token=f6d8cc26-3738-42d1-a60e-f7c40d8e1a5f"}
                                alt={"Death"}/>
                            <h5>The Death</h5>
                            <h6>by Rania Chrysostomou and Kamal Kaan</h6>
                          </span>
                </a>

                <a className={"ldCredits__card"} onClick={() => handleOpen("o")}>
                          <span>
                            <img
                                src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FOutsider.jpg?alt=media&token=663a4614-1106-4e9d-af8c-1d977eb13cec"}
                                alt={"Outsider"}/>
                            <h5>The Outsider</h5>
                            <h6>by John Kefala Kerr and Kamal Kaan</h6>
                          </span>
                </a>

            </div>
        </div>
    );
}

export default LockdownSubPageCards;