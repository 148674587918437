import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getFunctions} from "firebase/functions"
import {getStorage} from "firebase/storage"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB1Zoj1FGkBmetVBOBmJW9ie8UNbdFPSBE",
  authDomain: "skipton-camerata-cf704.firebaseapp.com",
  projectId: "skipton-camerata-cf704",
  storageBucket: "skipton-camerata-cf704.appspot.com",
  messagingSenderId: "568196353287",
  appId: "1:568196353287:web:730f08e65c3e13903b09a2",
  measurementId: "G-ZPVCC06Y5F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export {db, auth, storage, functions}
