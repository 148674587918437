import React, {useContext, useState} from "react"
import SocialBar from "./footer/SocialBar";
import Funders from "./footer/Funders";
import Legal from "./footer/Legal";
import FooterNavBar from "./footer/FooterNavBar";
import {DarkContext} from "../providers/DarkContext";
import FetchContent from "../dataManagement/FetchContent";


function Footer() {
  const {dark} = useContext(DarkContext)
  const [footerData, setFooterData] = useState(null)


  if (!footerData) return <FetchContent collection={"footer"} setData={setFooterData} />
  return (
    <footer className={"hide-on-print " + (!dark ? "pt-5" : "pt-0")}>
      <SocialBar icons={footerData[2] && footerData[2].socialBar} />
      <div className={"py-4 " + (!dark ? "background-white" : "background-dark")}>
        <div className={"container footerContainer"}>
          <Funders funders={footerData[0] && footerData[0].funders} dark={dark} />
          <Legal content={footerData[1] && footerData[1].legalInfo} />
          <FooterNavBar />
        </div>
      </div>
    </footer>
  )
}

export default Footer
