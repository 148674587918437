import React from "react";
import SearchCardBlueprint from "../components/blocks/SearchCardBlueprint";
import {LinkContainer} from "react-router-bootstrap";
import PageTitle from "../components/elements/PageTitle";
import MessageStrip from "../components/blocks/MessageStrip";
import ScreenContainer from "../components/blocks/ScreenContainer";

function Projects() {

  const projects = [
    {
      title: "Warriors of the Rainbow",
      subtitle: "They will be judged, not by empty words But by their actions and by their deeds.",
      href: "/projects/warriors-of-the-rainbow",
      img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fwarriors-of-the-rainbow%2Fwater-carrier.jpg?alt=media&token=1b6eb751-3187-4881-a1e5-bffd1b2a3994",
      imgAlt: "Warriors of the Rainbow",
    },
    {
      title: "Baht 'At Variations",
      subtitle: "“Do I need a hat on? Do I heck!\"",
      href: "/projects/baht-at-variations",
      img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2Fbaht-at-variations.jpeg?alt=media&token=5bc4baaf-7e87-4efe-9744-63311ab7fdfe",
      imgAlt: "Baht 'At Variations",
    },
    {
      title: "Lockdown Diaries",
      subtitle: "5 stories. 5 composers. 5 films.",
      href: "/projects/lockdown-diaries",
      img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Flockdown-katie-flute.jpeg?alt=media&token=81313ca1-dc70-4dc3-912c-1266504a7d73",
      imgAlt: "Lockdown Diaries",
    },
  ]

  return <ScreenContainer>
    <PageTitle title={"Projects"} dark={true} />
    <MessageStrip docId={"04Projects"} />

    {projects.map((project, i) => {
      return <>
        <SearchCardBlueprint href={project.href} img={project.img}
                             imgAlt={project.imgAlt} dark={true}>


          <LinkContainer to={project.href}>
            <a><h2>{project.title}</h2></a>
          </LinkContainer>

          <h3>{project.subtitle}</h3>

          <LinkContainer to={project.href}>
            <a className={"dark__light-font"}>Read More</a>
          </LinkContainer>


        </SearchCardBlueprint>
      </>
    })}

    <br />
  </ScreenContainer>
}

export default Projects;

// <LinkContainer to={linkTo}>
// <Nav.Link className="navbar__link">{linkTitle}</Nav.Link>
// </LinkContainer>
