import React, {useEffect, useState, useContext} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import ScrollToTop from "./components/siteTools/ScrollToTop";
import {DarkContext} from "./providers/DarkContext";
import {getDocTitle} from "./helper";

import Header from "./appBase/Header";
import Footer from "./appBase/Footer";

//Screens
import HomeScreen from "./screens/HomeScreen";
import WhatsOn from "./screens/WhatsOn";
import Projects from "./screens/Projects";
import PastEvents from "./screens/PastEvents";
import News from "./screens/News";
import PageTopImg from "./components/blocks/PageTopImg";
import GeneralScreen from "./screens/GeneralScreen";
import EventPage from "./screens/EventPage";
import NewsPost from "./screens/NewsPost";
import SupportUs from "./screens/SupportUs";
import LockdownDiaries from "./components/projects/lockdownDiaries/LockdownDiaries";
import BahtAtVariations from "./components/projects/bahtAtVariations/BahtAtVariations";
import WarriorsOfTheRainbow from "./components/projects/warriorsOfTheRainbow/WarriorsOfTheRainbow";
import Soundscapes from "./components/projects/soundscapes/Soundscapes";
import Contact from "./screens/Contact";
import SiteCookieConsent from "./components/siteTools/SiteCookieConsent";
import Policies from "./screens/Policies";

function App() {
  const [topImg, setTopImg] = useState(false)
  let {pathname} = useLocation()
  let {dark} = useContext(DarkContext)

  useEffect(() => {
    document.title = getDocTitle(pathname, null)
  }, [pathname])


  //Handle hide/show page top img
  useEffect(() => {
    setTopImg(true)
    const hideTopArr = ["/news/", "/whats-on/", "/projects/", "/policies/"]
    hideTopArr.forEach((path) => {
      if (pathname === "/" || (pathname.includes(path) && pathname.length > 10)) setTopImg(false)
    })

  }, [pathname])

  return (
    <div className={dark ? "dark" : ""}>
      <ScrollToTop>

        <Header />
        <PageTopImg show={topImg} />
        <Routes>

          <Route path="/" element={<HomeScreen />} exact />

          <Route path="/whats-on" element={<WhatsOn />} exact />
          <Route path="/whats-on/:idAndName" element={<EventPage />} exact />
          <Route path="/past-events" element={<PastEvents />} exact />
          <Route path="/news" element={<News />} exact />
          <Route path="/news/:idAndName" element={<NewsPost />} exact />
          <Route path="/about-us" element={<GeneralScreen />} exact />
          <Route path="/support-us" element={<SupportUs />} exact />
          <Route path="/contact-us" element={<Contact />} exact />
          <Route path="/terms-of-use" element={<GeneralScreen />} exact />
          <Route path="/privacy-notice" element={<GeneralScreen />} exact />
          <Route path="/cookies" element={<GeneralScreen />} exact />
          <Route path="/policies" element={<Policies />} exact />


          {/*Projects*/}
          <Route path="/projects" element={<Projects />} exact />
          <Route path="/projects/lockdown-diaries" element={<LockdownDiaries />} exact />
          <Route path="/projects/baht-at-variations" element={<BahtAtVariations />} exact />
          <Route path="/projects/warriors-of-the-rainbow" element={<WarriorsOfTheRainbow />} exact />
          <Route path="/projects/soundscapes" element={<Soundscapes />} exact />

        </Routes>

        <Footer />

        <SiteCookieConsent />
      </ScrollToTop>
    </div>
  );
}

export default App;
