import React, {useContext, useEffect} from "react";
import {DonorFormContext} from "../../../../providers/DonorFormContext";
import {db} from "../../../../dataManagement/firebase/firebaseConfig";

function DonorForm_ConfirmationContainer({setPageNum}) {
  const {form, customer} = useContext(DonorFormContext)

  useEffect(() => {
    db.collection("supporter_logs").doc(customer.id)
      .set({
        [form.formCreated_UTC]: {
          form,
          customer,
          paid: true,
          triedPaying: true,
          shownConfirmation: true
        }
      }, {merge: true})
  }, [])

  return (
    <>
      <span className={"line-height-tight"}>
        <h5 className={"mb-0 mt-3 line-height-tighter"}>Thank you for your donation and
        for {form.frequency === "ONE_TIME" ? "supporting Skipton Camerata." : "becoming a Friend of Skipton Camerata."}
        </h5>
      </span>


      <p className={"line-height-tight mt-3"}>You should soon be receiving your confirmation email.<br /></p>


      {/*TODO: Newsletter: check if not already signed up --> "Sure you don't want to sign up to newsletters?"*/}

      {/* TODO: If friend --> give details on subdomain for updating/cancelling subscription*/}

      <div className={"donor-form__footer px-0"}>
        <button className={"scBtn scbtn--2 scbtn--med w-100 "} id="submit" onClick={() => setPageNum(0)}>
          Back to start
        </button>
      </div>
    </>
  );
}

export default DonorForm_ConfirmationContainer;
