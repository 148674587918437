import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";

export const DarkContext = React.createContext({})

const DarkProvider = ({children}) => {
    const [dark, setDark] = useState(false);
    let pathname = useLocation().pathname

    useEffect(() => {
        setDark(pathname.includes("projects"))
    }, [pathname])

    return (
        <DarkContext.Provider value={{dark}}>
            {children}
        </DarkContext.Provider>
    );
};

export default DarkProvider