import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import BackButton from "../components/elements/BackButton";
import PageTitle from "../components/elements/PageTitle";
import NewsDate from "../components/news/NewsDate";
import SocialSection from "../components/news/SocialSection";
import PrevNextBnts from "../components/news/PrevNextBnts";
import Comments from "../components/news/commentsAndLikes/Comments";
import {getPrevNextPosts} from "../components/news/newsHelper";
import {clListener} from "../components/news/commentsAndLikes/commentsAndLikesHelper";
import ScreenContainer from "../components/blocks/ScreenContainer";
import HtmlReader from "../components/blocks/HtmlReader";
import FetchContent from "../dataManagement/FetchContent";


function NewsPost() {
  const {idAndName} = useParams()
  const [post, setPost] = useState(null)
  const [prevPost, setPrevPost] = useState({})
  const [nextPost, setNextPost] = useState({})
  const [comments, setComments] = useState([])
  const [likes, setLikes] = useState([])

  const id = idAndName.split("-").shift()

  console.log("HELLO - ID: ", id)

  useEffect(() => {
    post && post.datePublished && getPrevNextPosts(post.datePublished, setPrevPost, setNextPost)
  }, [post])

  useEffect(() => {
    if (prevPost.id === id) {
      setPost([prevPost])
    } else if (nextPost.id === id) {
      setPost([nextPost])
    }
    const unsubscribe = clListener(id, setLikes, setComments)

    return () => unsubscribe()
  }, [idAndName, id])


  if (!post || id !== post.id) return <FetchContent collection={"news"} docId={id} setData={setPost} />

  return <ScreenContainer key={id}>
    <div className={"smallContainer"}>
      <BackButton />
      <PageTitle title={post.title} pbSmall ptSmall />
      {/*DATE*/}
      <NewsDate postDate={post.dateCreated} className={"scPost-date mb-2"} />

      {/*HEAD IMG*/}
      {post.img !== null &&
      <div className="scPost-topImg">
        <img id={"blogMainImg"} className={"scPost-topImg__image"}
             src={post.img} alt={post.alt} />
      </div>}


      {/*BLOG BODY*/}
      <div id={"mainBody"}><HtmlReader htmlContent={post.body} /></div>

      {/*SOCIAL SECTION*/}
      <SocialSection likes={likes} postId={post.id} />

      {/*Comments*/}
      <Comments postId={post.id} comments={comments} />

      {prevPost.id && nextPost.id &&
      <PrevNextBnts prevPost={prevPost} nextPost={nextPost} />}

    </div>
  </ScreenContainer>
}

export default NewsPost;
