import React from 'react';
import NewsCard from "./NewsCard";

function HomeNewsCards({cards}) {
    return (
        <>
            <div className={"d-flex flex-wrap scHomeNewsCard"}>
                {cards && cards.map((card, i) => {
                    return (
                        <NewsCard key={i} homePage={true} homeIndex={i} card={{
                            img: card.img,
                            id: card.id,
                            alt: card.alt,
                            title: card.title,
                            summary: card.summary,
                            likes: card.likes,
                            dateCreated: card.dateCreated,
                        }}/>
                    )
                })}
            </div>
        </>
    );
}

export default HomeNewsCards;