import React from 'react';
import DarkProvider from "./DarkContext";
import ScrollProvider from "./ScrollContext";
import DbDataProvider from "./DbDataContext";
import DonorFormProvider from "./DonorFormContext";

function SiteProvider({children}) {

    return (
        <DonorFormProvider>
            <DarkProvider>
                <ScrollProvider>
                    <DbDataProvider>
                        {children}
                    </DbDataProvider>
                </ScrollProvider>
            </DarkProvider>
        </DonorFormProvider>
    );
}

export default SiteProvider;