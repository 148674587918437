import React from 'react';
import {LinkContainer} from "react-router-bootstrap";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function PrevNextBnts({prevPost, nextPost}) {

    return <>
        <div className={"scNxtPre row u-mar-top-2"}>
            <div className={"scNxtPre__previous col pr-1"}>
                {prevPost.id !== "--none--" ?
                    <LinkContainer to={`/news/${prevPost.id}-${prevPost.title}`}>
                        <a>
                            <button className={"scbtn--back"} type={"button"} name={"previous"}>
                                <FontAwesomeIcon icon={faArrowLeft}/><strong> Prevous</strong>
                            </button>
                            <p>{prevPost.title.substr(0, 30)}{prevPost.title.length > 30 && "..."}</p>
                        </a>
                    </LinkContainer>
                    : null}
            </div>

            <div className={"scNxtPre__next col pl-1"}>
                {nextPost.id !== "--none--" ?
                    <LinkContainer to={`/news/${nextPost.id}-${nextPost.title}`}>
                        <a>
                            <button className={"scbtn--back"} type={"button"} name={"next"}>
                                <strong>Next </strong><FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                            <p>{nextPost.title.substr(0, 30)}{nextPost.title.length > 30 && "..."}</p>
                        </a>
                    </LinkContainer>
                    : null}
            </div>
        </div>
    </>
}

export default PrevNextBnts;