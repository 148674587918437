import {db} from "../../../dataManagement/firebase/firebaseConfig";
import {doc, onSnapshot} from "firebase/firestore";

export const clListener = (docId, setLikes, setComments) => {
  return onSnapshot(doc(db, "likesComments", docId), (doc) => {
    const data = doc.data()
    setLikes(data && data.likes)
    setComments(data && data.comments)
  })
}


///Id maker
export const idMaker = () => {
  return new Date().toUTCString().replace(/[-:.TZ]/g, "")
}
