import React from 'react';

function LockdownTopParagraphs({pagePos1}) {
    //{useEffect, useRef, useState}
    // const [height, setHeight] = useState(0)
    // const ref = useRef(null)
    //
    // useEffect(() => {
    //     setHeight(ref.current.clientHeight)
    // }, [])

    return (
        <>
            <div className={"ldParallax__p-h2"}>
                <div id={"h2-s"}
                     className={"ldParallax__paragraph " + (pagePos1 && "ldParallax__flow--drop-s")}>
                    <div className={"ldParallax__flow--left10-wrap d-flex"}>
                        <h2 className={"ldParallax__flow--h2-start-1 mr-2"}>5 stories.</h2>
                        <h2 id={"start2"}
                            className={pagePos1 ? "ldParallax__flow--start-hide mr-2" : "ldParallax__flow--h2-start-2 mr-2"}>
                            5 composers.</h2>
                        <h2 id={"start3"}
                            className={pagePos1 ? "ldParallax__flow--start-hide mr-2" : "ldParallax__flow--h2-start-3 mr-2"}>
                            5 films.</h2>
                    </div>
                    <div className={"ldParallax__p ldParallax__flow--left10-wrap"}>
                        <p className={pagePos1 ? "opacity-show" : "opacity-hide"}>The coronavirus pandemic has
                            impacted so many people in so many
                            different ways. Their stories are told in the newspapers, on radio and television,
                            and over social media. They have been exchanged by neighbours across
                            garden walls and by mask-wearing shoppers. We have heard so many tales of joy and
                            sorrow, of courage and humour.</p>
                    </div>
                </div>

                <div id={"h2-c"}
                     className={"ldParallax__paragraph ldParallax__flow--left10-wrap mt-5  " + (!pagePos1 && "ldParallax__flow--drop")}>
                    <h2 id={"drop2"}
                        className={"ldParallax__paragraph--dropped-h2 " + (!pagePos1 && "ldParallax__flow--drop-hide")}>
                        5 composers.</h2>
                    <div className={"ldParallax__p"}>
                        <p className={pagePos1 ? "opacity-show" : "opacity-hide"}>We have taken five of those
                            extraordinary stories, compiled for
                            us by writer Kamal Kaan, and commissioned five composers - Jake Adams, Rania
                            Chrysostomou, John Kefala Kerr, Nicholas Olsen and Susannah Self - to write new
                            pieces based on the stories. </p>
                    </div>
                </div>

                <div id={"h2-f"}
                     className={"ldParallax__paragraph ldParallax__flow--left10-wrap mt-5 " + (pagePos1 && "ldParallax__flow--drop")}>
                    <h2 id={"drop3"}
                        className={"ldParallax__paragraph--dropped-h2 " + (!pagePos1 && "ldParallax__flow--drop-hide")}>
                        5 films.</h2>
                    <div className={"ldParallax__p"}>
                        <p className={pagePos1 ? "opacity-show" : "opacity-hide"}>
                            Skipton Camerata has recorded these and made them into a series of
                            films thanks to the technical and creative wizardry of our project partners,&nbsp;
                            <a style={{fontSize: "inherit", color: "#D98C4A"}}
                               href={"https://www.clothears.uk/"} target={"_blank"}
                               rel={"noreferrer"}>
                                Cloth Ears.
                            </a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LockdownTopParagraphs;