import React from 'react';

//// Page title

// 1. {title}
// ! title text

function PageTitle({title, dark = false, pbSmall = false, ptSmall = false}) {
    return (
        <div className={"w-100"}>
            <h1 className={"sc-page-title text-capitalize " + (dark && "projects-title") + (pbSmall ? " mb-0" : " pb-4") + (ptSmall ? " pt-4 pt-md-5" : " pt-5")}
                style={dark ? {color: "#eee"} : {}}>{title}</h1>
        </div>
    );
}

export default PageTitle;