import React from 'react';
import PageTitle from "../../elements/PageTitle";
import {Link} from "react-router-dom";

function EventNotesUnavailable({event, hide}) {

    if (!event[0]?.programmeNotes) return null
    return (
        <div className={hide ? "collapse" : ""}>
            <PageTitle title={event[0].title} pbSmall />
            <h4>Oh no! Notes for this event are currently unavailable.</h4>

            <Link to={`/whats-on/${event[0].id}-${event[0].title}`}>
                <h5 className={"mt-5 prime-colour"}>Go to event details</h5>
            </Link>
        </div>
    );
}

export default EventNotesUnavailable;