import React, {useEffect, useState} from 'react';
import DOMPurify from "dompurify";
import {Form} from "react-bootstrap";
import {updateData} from "../../../dataManagement/firebase/dbFunctions";
import {idMaker} from "./commentsAndLikesHelper";
import {newsDateFormat} from "../../../helper";

function CommentForm({postId, comments}) {
    const [showForm, setShowForm] = useState(false)
    const [form, setForm] = useState({
        userName: "",
        userComment: "",
        commentDate: null,
        commentId: idMaker()
    })

    const handleForm = (e) => {
        const {name, value} = e.target
        setForm(prevState => {
            return {...prevState, [name]: DOMPurify.sanitize(value)}
        })
    }

    const submitComment = (e) => {
        e.preventDefault()
        setForm(prevState => {
            return {...prevState, commentDate: newsDateFormat(new Date())}
        })
    }
    useEffect(() => {
        if (form.commentDate !== null) {
            comments.push(form)
            updateData("comments", comments, postId, "likesComments")
            setShowForm(false)
            setForm({
                userName: "",
                userComment: "",
                commentDate: null,
                commentId: idMaker()
            })
        }
    }, [form.commentDate])

    useEffect(() => {
        if (showForm === false && form.commentDate !== null) {
            setForm({userName: "", userComment: "", commentDate: null})
        }
    }, [showForm])

    return <>
        <button className={"scbtn--1 scbtn--med scComments__addBtn"} type={"button"}
                onClick={() => setShowForm(!showForm)}>
            Add Comment
        </button>

        <Form className={"scComments__addForm mb-4 " + (showForm ? "show-menu" : "hide-menu")}
              onSubmit={e => submitComment(e, form)}>
            <Form.Group className={"mt-1 mb-2"}>
                <Form.Label className={"readers-only"}>Your Name</Form.Label>
                <Form.Control type={"text"} className={"sc-commentForm"} placeholder={"Your Name"}
                              name={"userName"} onChange={handleForm} value={form.userName} />
            </Form.Group>
            <Form.Group>
                <Form.Label className={"readers-only"}>Your Comment</Form.Label>
                <Form.Control as={"textarea"} className={"sc-commentForm"} placeholder={"Your Comment"} rows={"8"}
                              name={"userComment"} onChange={handleForm} value={form.userComment} />
            </Form.Group>
            <button type={"submit"} className={"scbtn--2 scbtn--med scComments__submitForm"}>
                Submit
            </button>
        </Form>
    </>
}

export default CommentForm;
