import React, {useEffect, useState} from "react";
import Banner from "../components/banner/Banner";
import MessageStrip from "../components/blocks/MessageStrip";
import HomeNewsCards from "../components/news/HomeNewsCards";
import HomeEventsContainer from "../components/events/HomeEventsContainer";
import NewsletterSignup from "../components/blocks/NewsletterSignup";
import HomeAboutUs from "../components/blocks/HomeAboutUs";
import {Link} from "react-router-dom";
import HomeIntro from "../components/blocks/HomeIntro";
import {useFetchHomeContent} from "../dataManagement/useFetchHomeContent";

function HomeScreen() {
  const [data, setData] = useState(null)
  const [display, setDisplay] = useState(null)

  useFetchHomeContent(setData, setDisplay)

  console.log("HOME DATA: ", data)

  if (!data) return <div />;
  return (
    <>

      <Banner />
      <div className={"home-spacer"} />
      <div className={"home-spacer"} />

      {!!data && !!display && display.map((d, i) => {
        return <div key={i}>
          {d === "m" ?
           <>
             {data.message && data.message[0] && data.message[0].name !== "offline" &&
             <MessageStrip docId={"01Home"} homeContent={data.message} />}
           </> :
           d === "i" ?
           <>
             <HomeIntro intro={data.intro} />
             <div className={"home-spacer"} />
           </> :
           d === "a" ?
           <>
             <HomeAboutUs about={data.about} />
             <div className={"home-spacer"} />
           </> :
           d === "e" ?
           <>
             <HomeEventsContainer cards={data.events} />
             <div className={"container mt-0 text-right"}>
               <Link to={"/whats-on"}>See more events</Link>
             </div>
             <div className={"home-spacer"} />
           </> :
           d === "n" ?
           <> <HomeNewsCards cards={data.news} />
             <div className={"container mt-0 text-right"}>
               <Link to={"/news"}>Read more articles</Link>
             </div>
             <div className={"home-spacer"} />
           </> :
           d === "l" ?
           <> <NewsletterSignup />
             <div className={"home-spacer"} />
           </> :
           null}
          <div className={"pt-4"} />
        </div>
      })}
    </>
  );
}

export default HomeScreen;
