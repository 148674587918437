import React, {useContext} from 'react';
import P1_TabContent from "./P1_TabContent";
import {DonorFormContext} from "../../../../providers/DonorFormContext";

function P1Container_Amount() {
    const {form, handleChange} = useContext(DonorFormContext)

    if (!form) return null
    return (
        <>
            <div className="mt-3 donor-form__tabs">
                <div className={"d-flex justify-content-center"}>
                    <button id={form.frequency}
                            className={"scbtn--1 scbtn--small w-100 " + (form.frequency === "ONE_TIME" && "background-prime white-colour")}
                            onClick={() => handleChange("ONE_TIME", "frequency")}>Donor
                    </button>
                    <button className={"scbtn--1 scbtn--small w-100 " + (form.frequency === "MONTHLY" && "background-prime white-colour")}
                            onClick={() => handleChange("MONTHLY", "frequency")}>Friend
                    </button>
                </div>
                {form.frequency === "ONE_TIME" ?
                    <P1_TabContent type={"donor"} title={"Support us"} cta={<p className={"text-small mb-0"}>Make a <b>one-time</b> donation</p>} />
                    :
                    form.frequency === "MONTHLY" ?
                        <P1_TabContent type={"friend"} title={"Be a Friend of Skipton Camerata"}
                                       cta={<p className={"text-small mb-0"}>Make a <b>monthly</b> donation</p>} />
                        : null
                }
            </div>

        </>
    );
}

export default P1Container_Amount;