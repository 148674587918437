import React, {useContext} from 'react';
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {DarkContext} from "../../providers/DarkContext";

function HeaderLink({linkTo, linkTitle}) {
    let {dark} = useContext(DarkContext)
    return (
        <LinkContainer to={linkTo}>
            <Nav.Link className={"navbar__link " + (dark && "dark__light-font")}>{linkTitle}</Nav.Link>
        </LinkContainer>
    );
}

export default HeaderLink;