import React, {useState} from 'react'

export const DbDataContext = React.createContext({})

const DbDataProvider = ({children}) => {
    const [dbFetchedData, setDbFetchedData] = useState({
        news: [],
        newsTimout: null,
        pastEvents: [],
        pastEventsTimout: null,
        events: [],
        eventsTimout: null
    });

    return (
        <DbDataContext.Provider value={{dbFetchedData, setDbFetchedData}}>
            {children}
        </DbDataContext.Provider>
    );
};

export default DbDataProvider