import React, {useContext, useEffect, useRef, useState} from "react";
import {DonorFormContext} from "../../../../providers/DonorFormContext";
import {Col, Collapse, OverlayTrigger, Popover, Row} from "react-bootstrap";
import Input from "../../../elements/Input";
import Dropdown from "../../../elements/Dropdown";
import Checkbox from "../../../elements/Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {countries} from "countries-list";
import {functions} from "../../../../dataManagement/firebase/firebaseConfig";

const COUNTRY_LIST = Object.entries(countries).map(([code, {name, emoji}]) => {
  return {value: code, label: name}
})

function P2Container_PersonalDetails() {
  const {dbData, form, handleChange} = useContext(DonorFormContext)
  const [isUkAddress, setIsUkAddress] = useState(true);
  const [showAnonymousPopover, setShowAnonymousPopover] = useState(false);
  const target = useRef(null);


  useEffect(() => {
    setIsUkAddress(form.address.country === "GB")
  }, [form.address.country])

  const handleAddressChange = (v, k) => {
    form.address[k] = v
    handleChange(form.address, "address")
  }

  // const TEMP_TEST = async () => {
  //   // Sort form data before sending to server
  //   const {selectedAmount, customAmount, ...wantedData} = form
  //   const finalWantedData = {
  //     ...wantedData,
  //     amount: form.customAmountChecked ? form.customAmount : form.selectedAmount
  //   }
  //   const paymentConfirm = _functions.httpsCallable("handlePaymentConfirmation")
  //   const response = await paymentConfirm({form: finalWantedData})
  //   console.log("RES: ", response)
  // }


  return (
    <>
      {/*<button onClick={TEMP_TEST} className={"btn btn-dark my-4"}>TEST FUNC</button>*/}

      {/* NAMES */}
      <Row>
        <Col xs={6} className={"pr-1"}>
          <Input label={"First name"} keyToUpdate={"fName"} value={form.fName} handleChange={handleChange} required />
        </Col>
        <Col xs={6} className={"pl-1"}>
          <Input label={"Last name"} keyToUpdate={"lName"} value={form.lName} handleChange={handleChange} required />
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} className={"pr-0"}>
          <Checkbox checked={form.beAnonymousChecked}
                    handleChange={v => handleChange(v, "beAnonymousChecked")}
                    containerClassName={"donor-form__bottom-margin-spacer"}
                    label={"Make donation anonymous?"}
          />
        </Col>
        <Col className={"pl-1"}>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            key={"123"}
            placement={"top"}
            overlay={
              <Popover id={`popover-anonymous`}>
                <Popover.Content>
                  <p className={"text-smaller line-height-tight"}> We will always keep your personal information
                    private. This
                    option further marks your donation as
                    Anonymous in our records.</p>
                </Popover.Content>
              </Popover>
            }
          >
            <div ref={target}>
              <FontAwesomeIcon style={{width: "22px", height: "14px", top: "1.5px"}} className={"position-relative"}
                               onMouseEnter={() => setShowAnonymousPopover(true)}
                               onMouseLeave={() => setShowAnonymousPopover(false)}
                               icon={faInfoCircle} />
            </div>
          </OverlayTrigger>
        </Col>
      </Row>


      {/* EMAIL */}
      <Input label={"Email"} keyToUpdate={"email"} value={form.email} handleChange={handleChange} required />
      <Checkbox checked={form.mailListSignupChecked}
                handleChange={v => handleChange(v, "mailListSignupChecked")}
                containerClassName={"donor-form__bottom-margin-spacer"}
                label={<><strong>Subscribe to our mailing list</strong> to receive updates from us. You can unsubscribe
                  anytime.</>} />


      {/* ADDRESS */}
      <Input label={isUkAddress ? "Street Address" : "Address"} keyToUpdate={"line1"} value={form.address.line1}
             handleChange={handleAddressChange}
             required />

      <Collapse in={!isUkAddress}>
        <div>
          <Input keyToUpdate={"line2"} value={form.address.line2} handleChange={handleAddressChange} />
          <Input label={"City / Town"} keyToUpdate={"city"} value={form.address.city}
                 handleChange={handleAddressChange} />
          <Input label={"County / State / Province"} keyToUpdate={"state"} value={form.address.state}
                 handleChange={handleAddressChange} />
        </div>
      </Collapse>

      <Input label={isUkAddress ? "Postcode" : "Post / Zip code"} keyToUpdate={"postal_code"}
             value={form.address.postal_code}
             handleChange={handleAddressChange} required />
      <Dropdown label={"Country"} keyToUpdate={"country"} value={form.address.country}
                handleChange={handleAddressChange}
                optionList={COUNTRY_LIST} />

    </>
  );
}

export default P2Container_PersonalDetails;
