import React, {useEffect, useState} from 'react';
import {Collapse} from "react-bootstrap";

function Input({
                   label = null,
                   placeholder = null,
                   keyToUpdate,
                   handleChange,
                   value,
                   ClassName = "",
                   containerClassName = "",
                   looseMb4 = false,
                   handleBlur = null,
                   handleFocus = null,
                   required = false,
               }) {
    const [errMsg, setErrMsg] = useState({msg: "", show: false});

    const onBlur = (e) => {
        setErrMsg((!e.target.value && required) ? {msg: "This field is required", show: true} : {msg: errMsg.msg, show: false})
        if (handleBlur) handleBlur(e)
    }


    return <div className={"position-relative " + (looseMb4 ? "" : "mb-4 ") + (containerClassName)}>
        {label && <label className={"general-input__label"}>{label}</label>}
        <input type={"text"}
               placeholder={placeholder}
               className={"general-input " + (ClassName)}
               value={value}
               onChange={e => handleChange(e.target.value, keyToUpdate)}
               onBlur={e => onBlur(e)}
               onFocus={e => handleFocus ? handleFocus(e) : () => null}
        />
        <Collapse in={errMsg.show}>
            <p className={"line-height-tight text-smaller m-0 text-danger"}>{errMsg.msg}</p>
        </Collapse>
    </div>
}

export default Input;
