import React from "react";
import PageTitle from "../../elements/PageTitle";
import HtmlReader from "../../blocks/HtmlReader";
import BackButton from "../../elements/BackButton";
import EventSurveyAndDonorBtns from "./Event_SurveyAndDonorBtns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";

function EventNotesContainer({event, hide}) {

  if (!event[0]?.programmeNotes?.repertoireNotes) return null
  return (
    <div className={hide ? "collapse" : ""} id={"print-content"}>
      <div className={"position-absolute d-flex hide-on-print"}
           style={{right: "0", top: "0", marginTop: "calc(1.2rem + 1vw)"}}>
        <button onClick={() => window.print()} className={"sc-anchorLink font-colour bg-transparent border-0"}>
          <FontAwesomeIcon icon={faPrint} /><strong> Print</strong>
        </button>
      </div>


      <PageTitle title={event[0].title} pbSmall />
      <h2 className={"event__subtitle"}>{event[0].subtitle}</h2>

      <h1 className={"font-colour fw-normal"}>Programme Notes</h1>

      <EventSurveyAndDonorBtns event={event} position={"0"} />

      {event[0].programme !== "" &&
      <HtmlReader htmlContent={event[0].programme} classNames={"event__info"} editorId={"notes-programme"} />}

      <EventSurveyAndDonorBtns event={event} position={"1"} />

      <br />
      {event[0].programmeNotes.repertoireNotes !== "" &&
      <HtmlReader htmlContent={event[0].programmeNotes.repertoireNotes} classNames={"event__info"}
                  editorId={"notes-repertoireNotes"} />}

      <EventSurveyAndDonorBtns event={event} position={"2"} />

      <br />
      {event[0].programmeNotes.performersNotes !== "" &&
      <HtmlReader htmlContent={event[0].programmeNotes.performersNotes} classNames={"event__info"}
                  editorId={"notes-performersNotes"} />}

      <EventSurveyAndDonorBtns event={event} position={"3"} />

      <BackButton text={"Return to event page"} className={"prime-colour"} to={"?return_to=whats-on"} /></div>
  );
}

export default EventNotesContainer;