import React from 'react';
import FooterLink from "./FooterLink";
import {useLocation} from "react-router-dom";

function FooterNavBar() {
    let pathname = useLocation().pathname
    let showNoah = ["/", "/whats-on", "/projects", "/news", "/about-us", "/support-us", "/contact-us"]

    return <>
        <div style={{position: "relative"}} className={"d-block text-center d-lg-flex justify-content-between pb-2"}>
            <ul className="sc-FooterUl list-group list-group-horizontal justify-content-center justify-content-md-start">
                <FooterLink linkTitle={"Terms of Use"} linkTo={"/terms-of-use"}/>
                <FooterLink linkTitle={"Privacy"} linkTo={"/privacy-notice"}/>
                <FooterLink linkTitle={"Cookies"} linkTo={"/cookies"}/>
                <FooterLink linkTitle={"Policies"} linkTo={"/policies"}/>
                <FooterLink linkTitle={"Contact Us"} linkTo={"/contact-us"}/>
            </ul>
            <ul className={"list-group list-group-horizontal-sm justify-content-center justify-content-md-start"}>
                <li className={"list-unstyled"}>
                    {showNoah.includes(pathname) &&
                    <a className="footAnchors" href="https://noahgwynn.io" target={"_blank"} rel={"noreferrer"}>
                        Site by Noah Gwynn
                    </a>}
                </li>
            </ul>
        </div>
    </>
}

export default FooterNavBar;