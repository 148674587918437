import React, {useState} from "react";
import {Spinner} from "react-bootstrap";
import {triggerEmail} from "../../helper";

function ContactForm({emailTo}) {
  const [form, setForm] = useState({
    user_name: "",
    email_address: "",
    message: "",
    subject: "Contact Form Message",
  })
  const [status, setStatus] = useState({sending: false, sent: false, err: false, errMsg: ""})


  //Handle Form Submit
  const sendEmail = (e) => {
    e.preventDefault()
    triggerEmail(form, setForm, setStatus, emailTo)
  }

  // handle changes
  const handleChange = (e) => {
    const {name, value} = e.target
    setForm(prevState => {
      return {...prevState, [name]: value}
    })
  }

  return <>
    <form className={"mb-5"} onSubmit={sendEmail}>
      <label className={"readers-only"}>{"Your Name"}</label>
      <input type={"text"} placeholder={"Your Name"} className={"general-input email mb-2"}
             value={form.user_name} name={"user_name"} onChange={handleChange} />

      <label className={"readers-only"}>{"Your Email. We'll never share it."}</label>
      <input type={"text"} placeholder={"Your Email. We'll never share it."}
             className={"general-input email mb-2"} value={form.email_address} name={"email_address"}
             onChange={handleChange} />

      <label className={"readers-only"}>Your Message</label>
      <textarea placeholder={"Your Message"} className={"general-input email mb-2"}
                value={form.message} name={"message"} onChange={handleChange} rows={8} />

      <div className={"d-flex"}>
        <button type="submit" className="scbtn--1 scbtn--med mt-2" disabled={status.pending}
                style={{minWidth: "11.3rem"}}>
          {status.pending ? <Spinner animation={"border"} /> : "Submit"}
        </button>
        <p
          className={"ml-auto mt-1 text-right text-small font-weight-bold line-height-tight pl-2 " + (!status.err ? "text-success" : "text-danger")}>{status.errMsg}</p>
      </div>
    </form>
  </>
}

export default ContactForm;
