import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHeart} from "@fortawesome/free-solid-svg-icons"
import NewsDate from "./NewsDate";
import {getDocById} from "../../dataManagement/firebase/dbFunctions";

function NewsCard({card, homePage, homeIndex = 0}) {
  const [likes, setLikes] = useState(0);
  useEffect(() => {
    getDocById("likesComments", card.id)
      .then(doc => {
        setLikes(doc.likes)
      })
  }, [])
  return (
    <>
      <figure
        className={"scNewsCard__wrap " + (homePage && "scHomeNewsCard__wrap " + (homeIndex === 0 ? "pr-md-2 mb-4" : "pl-md-2 mb-3"))}>
        <Link to={`/news/${card.id}-${card.title}`} className="scNewsCard__anchor">
          <>
            <img className="scNewsCard__img" src={card.img} alt="" />
            <div className="scNewsCard__header px-4 py-2">
              <div className="row scNewsCard__header--top-wrap">
                <small className="col">
                  <NewsDate postDate={card.dateCreated} className={"scNewsCard__header--date"} />
                </small>
                <div className="col scNewsCard__header--heart-div">
                  <p className="sc-heartBackgound scNewsCard__header--heart" id="likeInt">
                    <FontAwesomeIcon icon={faHeart} /> {likes}
                  </p>
                </div>
              </div>
              <h3>{card.title}</h3>
            </div>
            <div className="col scNewsCard__desc p-4">
              <p>{card.summary}</p>
              <Link className="col sc-anchorLink pl-0" to={`/news/${card.id}-${card.title}`}>Read
                More</Link>
            </div>
          </>
        </Link>
      </figure>


    </>
  );
}

export default NewsCard;
