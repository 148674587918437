import React from 'react';

function Textarea({
                      label = null,
                      placeholder,
                      rows,
                      keyToUpdate,
                      value,
                      handleChange,
                      handleBlur = null,
                      handleFocus = null,
                      className = "",
                      containerClassName = "",
                      showResizeHandle = false,
                  }) {

    return <div className={containerClassName}>
        {label && <label className={"general-input__label"}>{label}</label>}
        <textarea placeholder={placeholder}
                  className={"general-input " + className}
                  style={{resize: showResizeHandle ? "auto" : "none"}}
                  value={value}
                  rows={rows}
                  onChange={e => handleChange(e.target.value, keyToUpdate)}
                  onBlur={e => handleBlur ? handleBlur(e) : () => null}
                  onFocus={e => handleFocus ? handleFocus(e) : () => null} />
    </div>
}

export default Textarea;