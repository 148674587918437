import React, {useState} from "react";
import FetchContent from "../dataManagement/FetchContent";
import PageTitle from "../components/elements/PageTitle";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import ScreenContainer from "../components/blocks/ScreenContainer";
import MessageStrip from "../components/blocks/MessageStrip";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HtmlReader from "../components/blocks/HtmlReader";

function Policies() {
  const [policies, setPolicies] = useState(null)
  const [currentSelected, setCurrentSelected] = useState(null);

  console.log(policies)


  const handleOnClick = (eventKey, i) => {
    const selected = currentSelected !== eventKey ? eventKey : null
    setCurrentSelected(selected)
  }

  const orderPolicies = () => {
    const filtered = policies.filter((p) => !!p.title);
    return filtered.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0));
  }

  if (!policies) return <FetchContent collection={"policies"} setData={setPolicies} />
  return <ScreenContainer>
    <PageTitle title={"Policies"} />

    <MessageStrip docId={"09Policies"} />

    <Accordion defaultActiveKey={"0"}>
      {!!policies && orderPolicies().map((policy, i) => {
        console.log(i, policy.title)
        return <div key={i + policy.title}>
          <Card className={"mb-4"} style={{cursor: "pointer"}}>
            <Accordion.Item eventKey={policy.title} onClick={() => handleOnClick(policy.title, i)}>
              <Accordion.Button as={"div"}>
                <Card.Header className={"m-0 p-0 border-0"}>
                  <Row className={"search-card m-0 px-0"}>
                    <Col xs={10}>
                      <h2 className={"policies-titles"}>{policy.title}</h2>
                    </Col>
                    <Col xs={2}
                         className={"d-flex justify-content-start align-items-end justify-content-end"}>
                      <a className={"prime-colour"}><FontAwesomeIcon
                        icon={currentSelected === policy.title ? faMinus : faPlus} /></a>
                    </Col>
                  </Row>
                </Card.Header>
              </Accordion.Button>
              <Accordion.Body eventKey={policy.title}>
                <div className={"px-4 pb-4"}>
                  <HtmlReader htmlContent={policy.body} editorId={"policies-body"} />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </div>
      })}

    </Accordion>

  </ScreenContainer>
}

export default Policies;
