import moment from "moment";

export const createDonorFormData = () => {
    return {
        formCreated_UTC: moment.utc().format(),
        // Donate amount
        customAmountChecked: false,
        customAmount: 0,
        selectedAmount: 1000,
        // One-time / repeat
        frequency: "ONE_TIME", // freq option: [ONE_TIME, MONTHLY]
        // User Comment
        includeCommentChecked: false,
        comment: "",
        // Gift Aid
        agreedToGiftAidChecked: false,
        // Personal Info
        fName: "",
        lName: "",
        beAnonymousChecked: false,
        email: "",
        mailListSignupChecked: false,
        // Address
        address: {
            line1: "",
            line2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "GB",
        },
    }
}