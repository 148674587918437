//Get content from dataManagement page content data
import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {db} from "./firebase/firebaseConfig"

import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {getDocById} from "./firebase/dbFunctions";

export function useFetchHomeContent(setContent, setDisplay) {


  const fetch = async (query) => {
    let dataArray = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach((doc) => {
      dataArray.push(doc.data())
    })
    return dataArray
  }

  useEffect(() => {
    const data = {}
    const now = moment().tz("Europe/London").format()
    // News
    fetch(query(collection(db, "news"),
      where("datePublished", "<", now),
      where("online", "==", true),
      where("homePage", "==", true),
      orderBy("datePublished", "desc"),
      limit(2)
    )).then(news => data.news = news)
    // Events
    fetch(query(collection(db, "events"),
      where("expireToPast", ">", now),
      where("online", "==", true),
      where("homePage", "==", true),
      orderBy("expireToPast"),
      limit(2)
    )).then(events => data.events = events)
    // About
    fetch(query(collection(db, "general"),
      where("fetchId", "==", "homeAbout"),
    )).then(about => data.about = about)
    // Intro
    fetch(query(collection(db, "general"),
      where("fetchId", "==", "homeIntro")
    )).then(intro => data.intro = intro)
    // message Strip
    fetch(query(collection(db, "messageStrip"),
      where("page", "==", "Home")
    )).then(message => data.message = message)

    setContent(data)
  }, [])


  // Display Order
  useEffect(() => {
    getDocById("general", "homeDisplay")
      .then(doc => {
        setDisplay(doc.display)
      })
      .catch(() => {
      })
  }, [])


  return null;
}
