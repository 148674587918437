import React, {useEffect, useRef, useState} from "react"

export const ScrollContext = React.createContext({})

const ScrollProvider = ({children}) => {
  const yPos = useRef(window.scrollY)
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    window.onscroll = () => {
      yPos.current = window.scrollY //Current position
      yPos.current <= 10 ? setScrollPos(0) :
      yPos.current <= 1000 ? setScrollPos(999) :
      yPos.current > 1000 ? setScrollPos(1001) :
      setScrollPos(0)
    }
  })

  return (
    <ScrollContext.Provider value={{scrollPos, yPos: yPos.current}}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider
