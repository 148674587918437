import React, {useContext, useEffect, useState} from "react";
import {ScrollContext} from "../../../providers/ScrollContext";
import ProjectTemplateOne from "../ProjectTemplateOne";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {frontline, antiMasker, death, outsider, survivor} from "./SubPages";
import LockdownTopParagraphs from "./LockdownTopParagraphs";
import LockdownSubPageCards from "./LockdownSubPageCards";

function LockdownDiaries() {
  const [imgLoaded, setImgLoaded] = useState(false)
  const [subPage, setSubPage] = useState(null)
  const [pagePos1, setPagePos1] = useState(window.scrollY > 140)
  const [pagePos2, setPagePos2] = useState(window.scrollY > 1000)
  let {yPos} = useContext(ScrollContext)
  const [yScrollLock, setYScrollLock] = useState(null);


  const handleScrollLock = (action) => {
    if (action === "lock") setYScrollLock(window.scrollY)
    if (action === "unlock") window.scroll(0, yScrollLock)
  }

  useEffect(() => {
    yPos > 10 && pagePos1 === false && setPagePos1(true)
    yPos <= 10 && pagePos1 === true && setPagePos1(false)
    yPos > 1000 && pagePos2 === false && setPagePos2(true)
    yPos <= 1000 && pagePos2 === true && setPagePos2(false)
  }, [yPos])


  return <>
    {/*Sub Page*/}
    <div className={"sub-page sub-page__1 " + (subPage && "sub-page__open")}>
      <a style={{zIndex: "100"}} className={"sub-close-btn"} onClick={() => {
        setSubPage(null)
        handleScrollLock("unlock")
      }}>
        <FontAwesomeIcon icon={faTimes} />
      </a>
      <ProjectTemplateOne setSubPage={setSubPage} useNextAndPrevBtns={true}
                          data={subPage === "f" ? frontline : subPage === "a" ? antiMasker : subPage === "d" ? death : subPage === "o" ? outsider : subPage === "s" ? survivor : null} />
    </div>

    {/*Lockdown*/}
    <div className="ldParallax">
      {!imgLoaded && <div className={"vh-100"} />}

      <img className={(imgLoaded ? "ldParallax__img " : "") + (pagePos2 && "opacity-hide")}
           src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fkatie_flute.jpg?alt=media&token=c28e15b2-5cd6-4c03-8703-a1f98fef1a0a"}
           alt={"Katie with a flute"} onLoad={() => setImgLoaded(true)} />


      {imgLoaded && <div>
        <section id={"flow"} className={"ldParallax__flow container"}>
          <div className={"ldParallax__flow--sticky"}>

            <div className={"ldParallax__flow--title-bg"}>
              <h1 className={"sc-page-title"}>Lockdown Diaries</h1>
            </div>

            <LockdownTopParagraphs pagePos1={pagePos1} />
          </div>
        </section>


        <div id={"watch"}
             className={"ldParallax__paragraph ldParallax__watch-now container " + (pagePos2 && "move-in-left")}>
          <h4>Watch Now</h4>
        </div>

        {/*Sub Page Cards*/}
        <section className={"ldBase"}>
          <div className={"ldClip"} />

          <LockdownSubPageCards setSubPage={setSubPage} handleScrollLock={handleScrollLock} />

          <div className={"cta container"}>
            <p className={"cta__text"}>
              Click on the above links to watch the films and find out more about
              Lockdown Diaries, or watch the trailer:
            </p>
          </div>

          {/*trailer*/}

          <div className={"container p-0"}>
            <div className={"ld-trailer__wrapper u-mar-top-8"}>
              <iframe className={"ld-trailer__iframe"}
                      src={"https://www.youtube.com/embed/Ihr_6FdiCAw?rel=0&modestbranding=1"}
                      frameBorder={"0"}
                      allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                      allowFullScreen />
            </div>
          </div>

          {/*footnote*/}
          <div className={"ldFootnote mb-3 container"}>
            <p>
              Lockdown Diaries has been made possible thanks to the generosity of Skipton Building
              Society,
              the Garfield Weston Foundation and Sheffield Oratorio Chorus.
            </p>
          </div>


        </section>
      </div>}
    </div>
  </>
}

export default LockdownDiaries;
