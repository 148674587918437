import React, {useEffect, useState} from 'react';
import HtmlReader from "../blocks/HtmlReader";
import {Link} from "react-router-dom";

function HomeAboutUs({about}) {
    const [title, setTitle] = useState(null);
    let styles;
    let bg;
    let data

    if (about && about[0].homeAbout && about[0].homeAbout.titleStyles) {
        data = about[0].homeAbout
        styles = about[0].homeAbout.titleStyles
        // BG Colour
        bg = about && styles.bg === "n" ? "transparent" : styles.bg === "o" ? "#ff9605" : styles.bg === "w" ? "#fff" : styles.bg === "d" ? "#0C0D04" : null
    }

    useEffect(() => {
        if (about && about[0].homeAbout && about[0].homeAbout.titleStyles) {
            let tempArr = []
            // Tag
            let tag = styles.type.includes("h2") ? ["<h2", "</h2>"] : styles.type.includes("h3") ? ["<h3", "</h3>"] : ["<p", "</p>"]
            // Colour
            let colour = styles.type.includes("o-") ? "prime-colour" : styles.type.includes("w-") ? "white-colour" : ""
            // Text Size
            let sizeClass = styles.type.includes("lg") ? "lg-title" : styles.type.includes("md") ? "md-title" : styles.type.includes("h3") ? "sm-title" : ""
            // Push HTML to tempArr
            tempArr.push(`${tag[0]} class="${sizeClass} ${colour} m-0" style="font-weight:${styles.weight}">${data.title}${tag[1]}`)

            setTitle(tempArr.join(""))
        }
    }, [about])


    return <>
        {about && about && data && data.online &&
        <div className={"container home-about overflow-hidden"}>
            <Link to={data.link} style={{cursor: data.linkActive ? "pointer" : "default"}}
                  onClick={e => data.linkActive ? null : e.preventDefault()}>
                <div className={"home-about__img-wrap position-relative"}
                     style={{backgroundImage: `url(${data.img})`}}>
                    <div className={"home-about__title-wrap"} style={{backgroundColor: bg}}>
                        {/*<h2>{title}</h2>*/}
                        <HtmlReader classNames={""}
                                    htmlContent={title} />
                    </div>
                </div>
            </Link>
            <div className={"home-about__text-wrap"}>
                < HtmlReader htmlContent={data.text} editorId={"home-about-us"} />
            </div>
        </div>}
    </>
}

export default HomeAboutUs;