import React from "react";
import HomeOneEventCard from "./HomeOneEventCard";
import HomeTwoEventCards from "./HomeTwoEventCards";

function HomeEventsContainer({cards}) {
  console.log("CARDS: ", cards)
  return <>
    {cards && cards.length === 1 ?
     <>
       <HomeOneEventCard cards={cards} />
     </>
                                 :
     cards && cards.length === 2 ?
     <>
       <HomeTwoEventCards cards={cards} />
     </>
                                 : null
    }
  </>
}

export default HomeEventsContainer;
