import React, {useContext, useState} from 'react';
import {DonorFormContext} from "../../../../providers/DonorFormContext";
import {Col, Collapse, Row} from "react-bootstrap";
import Input from "../../../elements/Input";

function P1_AmountBtns({type}) {
    const {dbData, form, handleChange, donorConstants: {MINIMUM_DONATION}, formatAmount} = useContext(DonorFormContext)
    const [errMessage, setErrMessage] = useState(null);


    const handleCustomChange = (x, k, customChecked = true) => {
        let res = x.length > 1 ? x.slice(2) : x
        res = parseFloat(res) / Math.pow(10, -2)
        handleChange(isNaN(res) ? 0 : res, k)
        form.customAmountChecked !== customChecked && handleChange(customChecked, "customAmountChecked")
    }

    const checkCustomAmountExceedsMinimum = (e) => {
        const {value} = e.target
        const message = `Please enter a minimum of £${formatAmount(MINIMUM_DONATION)}.`
        setErrMessage(parseFloat(value.slice(2)) / Math.pow(10, -2) <= MINIMUM_DONATION ? message : null)
    }

    return (
        <>
            <Row style={{paddingLeft: "15px", paddingRight: "15px"}}>
                {dbData[type === "donor" ? "donorAmounts" : "friendAmounts"].map((b, i) => {
                        return <Col xs={4} className={"p-0"} key={i}>
                            <button
                                className={"donor-form__amount-btn scbtn--1 " + (b === form.selectedAmount && "background-prime white-colour")}
                                style={{borderWidth: i === 1 ? "1px 0" : "1px"}}
                                onClick={() => {
                                    handleChange(b, "selectedAmount")
                                    handleCustomChange("£0", "customAmount", false)
                                }}
                            >
                                £{formatAmount(b)}
                            </button>
                        </Col>
                    }
                )}
            </Row>

            {/* CUSTOM AMOUNT */}
            <div>
                <div className={"m-0 p-0"}>
                    <Input value={form.customAmount === 0 ? "" : `£ ${formatAmount(form.customAmount)}`}
                           handleChange={handleCustomChange}
                           handleBlur={checkCustomAmountExceedsMinimum}
                           handleFocus={() => handleChange(true, "customAmountChecked")}
                           keyToUpdate={"customAmount"}
                           placeholder={"£ Custom Amount"}
                           containerClassName={"mt-2 mb-0"}
                           looseMb4
                    />
                </div>
                <Collapse in={!!errMessage && form.customAmount < MINIMUM_DONATION && form.customAmount > 0} key={"errMessage"}>
                    <p className={"m-0 p-0 text-smaller line-height-tight text-danger"}>{errMessage}</p>
                </Collapse>
            </div>
        </>
    );
}

export default P1_AmountBtns;