import React from 'react';
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

function FooterLink({linkTo, linkTitle}) {
    return (
        <li className={"list-unstyled"}>
            <LinkContainer to={linkTo}>
                <Nav.Link className="footAnchors">{linkTitle}</Nav.Link>
            </LinkContainer>
        </li>
    );
}

export default FooterLink;