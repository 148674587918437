import {db} from "../../dataManagement/firebase/firebaseConfig";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";


export const getPrevNextPosts = (dateCreated, setPrevPost, setNextPost) => {

  fetchPrevNext("<", dateCreated, "desc")
    .then(doc => {
      doc !== undefined ? setPrevPost(doc)
                        : setPrevPost({title: "--none--", id: "--none--"})
    })
    .catch(err => {
      setPrevPost({title: "--none--", id: "--none--"})
    })
  fetchPrevNext(">", dateCreated, "asc")
    .then(doc => {
      doc !== undefined ? setNextPost(doc)
                        : setNextPost({title: "--none--", id: "--none--"})
    })
    .catch(err => {
      setNextPost({title: "--none--", id: "--none--"})
    })
}

const fetchPrevNext = (operator, date, direction) => {
  return new Promise((resolve, reject) => {

    getDocs(query(collection(db, "news"),
      where("datePublished", operator, date),
      where("online", "==", true),
      orderBy("datePublished", direction),
      limit(1)
    )).then((snapshot) => {
      let dataArray = [];
      snapshot.docs.forEach(doc => {
        dataArray.push(doc.data())
      })
      resolve(dataArray[0])
    })
      .catch(err => {
        reject(err)
      })


  })
}
