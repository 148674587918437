import React from 'react';
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {newsDateFormat} from "../../helper";

function NewsDate({postDate, className}) {

    return <div className={"d-flex"}>
        <p className={className}>
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span style={{position: "relative", top: ".6px"}}> {newsDateFormat(postDate)}</span>
        </p>
    </div>
}

export default NewsDate;