import React, {useContext, useEffect, useState} from "react";
import {ScrollContext} from "../../../providers/ScrollContext";
import ProjectTemplateOne from "../ProjectTemplateOne";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import RotatingImages from "./RotatingImages";
import LockdownTopParagraphs from "../lockdownDiaries/LockdownTopParagraphs";
import SoundscapesParagraphs from "./SoundscapesParagraphs";

function Soundscapes() {
  let {scrollPos, yPos} = useContext(ScrollContext)
  const [showImageNumber, setShowImageNumber] = useState(1);
  const [pagePos1, setPagePos1] = useState(window.scrollY > 8)

  useEffect(() => {
    if (yPos > 10) {
      setPagePos1(true)
    } else {
      setPagePos1(false)
    }
  }, [yPos])

  return <>
    <RotatingImages showImageNumber={showImageNumber} />
    <div className={"ssParallax"}>


      <section className={"ssParallax__flow " + (pagePos1 && " opacity-hide")}>
        <div className={"ssParallax__flow--sticky"}>

          <div className={"ssParallax__flow--title-bg"}>
            <h1 className={"sc-page-title"}>&nbsp;Soundscapes</h1>
            <h3 className={"sc-page-title"}>&nbsp;Skipton Town Hall | 14 March to 1 June</h3>
          </div>

        </div>
      </section>


      <SoundscapesParagraphs setShowImageNumber={setShowImageNumber} />


    </div>
  </>
}

export default Soundscapes;
