import React from 'react';
import Likes from "./commentsAndLikes/Likes";
import SocialShareBtns from "./SocialShareBtns";
import {Col, Row} from "react-bootstrap";

function SocialSection({likes, postId}) {

    return <>
        <Row className={"scSocial mt-2 mt-md-5 pt-3"}>
            <Col xs={3}>
                {/*Likes*/}
                <Likes likes={likes} postId={postId}/>
            </Col>
            <Col xs={9} className={"d-block w-100 text-right"}>
                {/*Social Share*/}
                <SocialShareBtns/>
            </Col>
        </Row>
    </>
}

export default SocialSection;