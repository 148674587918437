import React, {useContext, useEffect, useState} from "react"
import {LinkContainer} from "react-router-bootstrap"
import {Container, Image} from "react-bootstrap"
import NavDesktop from "./header/NavDesktop";
import NavMobile from "./header/NavMobile";
import {DarkContext} from "../providers/DarkContext";
import {ScrollContext} from "../providers/ScrollContext";
import {getDocById} from "../dataManagement/firebase/dbFunctions";
import {useLocation} from "react-router-dom";

//// header

// Needs modifications
// line 23, logos src
// line 24 logos alt

function Header() {
  const [headerBtn, setHeaderBtn] = useState(null)
  const [pageTop, setPageTop] = useState(window.scrollY < 10)
  const path = useLocation().pathname
  console.log("PATH: ", path)

  let {dark} = useContext(DarkContext)
  let {yPos} = useContext(ScrollContext)
  useEffect(() => {
    if (path.includes("projects/")) setPageTop(false)
    else if (yPos <= 9) setPageTop(true) //set if top of page
    else if (yPos >= 10) setPageTop(false) //set if not top of page
  }, [yPos, path])

  useEffect(() => {
    getDocById("general", "headerBtn")
      .then(doc => {
        setHeaderBtn(doc.headerBtn[0])
      })
  }, [])


  return (
    <>
      {/* PRINT HEADER */}
      <header className={"header-for-print container"}>
        <Image className={"logo-for-print m-0 p-0"} src={"/images/logos/sc-grey-lg.png"} alt="Square Logo Grey" />
      </header>
      {/* SITE HEADER */}
      <header className={"hide-on-print"}>
        <nav style={{zIndex: "99999"}}
             className={"navbar " + (pageTop ? "lg-header " : "sm-header ") + (dark && "dark")}>
          <Container className={"header-container position-relative"}>
            <LinkContainer to="/">
              <div className={"logo"}>
                <Image
                  className={"logo__sm m-0 p-0 position-absolute " + (pageTop ? "hide-logo-sm " : "show-logo ") + (!dark ? "opacity-0" : "opacity-1")}
                  src={"/images/logos/sc-white-sm.png"}
                  alt="Skipton Camerata Logo Grey"
                  fluid />
                <Image
                  className={"logo__sm m-0 p-0 position-absolute " + (pageTop ? "hide-logo-sm " : "show-logo ") + (dark ? "opacity-0" : "opacity-1")}
                  src={"/images/logos/sc-grey-sm.png"}
                  alt="Skipton Camerata Logo Grey"
                  fluid />
                <Image
                  className={"logo__lg m-0 p-0 position-absolute " + (pageTop ? "show-logo " : "hide-logo-lg ") + (!dark ? "opacity-0" : "opacity-1")}
                  src={"/images/logos/sc-white-lg.png"}
                  alt="Skipton Camerata Logo White"
                  fluid />
                <Image
                  className={"logo__lg m-0 p-0 position-absolute " + (pageTop ? "show-logo " : "hide-logo-lg ") + (dark ? "opacity-0" : "opacity-1")}
                  src={"/images/logos/sc-grey-lg.png"}
                  alt="Square Logo Grey"
                  fluid />
              </div>
            </LinkContainer>

            <NavDesktop />
            <NavMobile />

            <div
              className={"position-absolute header-btn " + (headerBtn && headerBtn.display && pageTop ? "show-logo " : "hide-logo-sm ")}>
              {headerBtn && headerBtn.display &&
              <a href={headerBtn.link} target={"_blank"} rel={"noreferrer"}>
                <button className={"header-btn--btn scbtn--2 "} type={"button"}
                        onClickCapture={(e) => e.currentTarget.blur()}>{headerBtn.title}</button>
              </a>}
            </div>
          </Container>
        </nav>
        <div className={pageTop ? "lg-header" : "sm-header"}>&nbsp;</div>
      </header>
    </>
  )
}

export default Header
