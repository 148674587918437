import React, {useState} from "react";
import {Link} from "react-router-dom";
import SearchCardBlueprint from "../components/blocks/SearchCardBlueprint";
import PageTitle from "../components/elements/PageTitle";
import {eventDateFormat} from "../helper";
import MessageStrip from "../components/blocks/MessageStrip";
import ScreenContainer from "../components/blocks/ScreenContainer";
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";

function WhatsOn() {
  const [events, setEvents] = useState([])

  return <ScreenContainer>
    <FetchWithEndlessScroll currentPageDocs={events} setCurrentPageDocs={setEvents} type={"events"}>
      <PageTitle title={"What's On"} />
      <MessageStrip docId={"02WhatsOn"} />


      {events && events.map((event, i) => {
        return <div key={i}>
          <SearchCardBlueprint href={`/whats-on/${event.id}-${event.title}`} img={event.img}
                               imgAlt={event.alt}>

            <Link to={`/whats-on/${event.id}-${event.title}`}>
              <h2>{event.title}</h2>
            </Link>

            <h3>{event.subtitle}</h3>

            {event.eventDates && event.eventDates.map((date, i) => {
              return <p key={i} className={"search-card__wo-date"}>{eventDateFormat(date)}</p>
            })}
            <p className={"search-card__wo-venue"}>{event.venue}</p>

            <div>
              <p className={"search-card__details"}>{event.summary}</p>
            </div>

            <Link to={`/whats-on/${event.id}-${event.title}`}>
              Read More
            </Link>

          </SearchCardBlueprint>
        </div>
      })}
    </FetchWithEndlessScroll>
  </ScreenContainer>
}

export default WhatsOn;
