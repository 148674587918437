import React, {useState} from "react";
import PageTitle from "../components/elements/PageTitle";
import MessageStrip from "../components/blocks/MessageStrip";
import HtmlReader from "../components/blocks/HtmlReader";
import ScreenContainer from "../components/blocks/ScreenContainer";
import {useLocation} from "react-router-dom";
import FetchContent from "../dataManagement/FetchContent";

function GeneralScreen() {
  const path = useLocation().pathname.replace("/", "")
  const pathId = path.replaceAll("-", "")
  const [content, setContent] = useState(null)

  if (!content) return <FetchContent collection={"general"} docId={pathId} setData={setContent} />
  return <ScreenContainer>
    <PageTitle title={path.replaceAll("-", " ")} />
    {pathId === "aboutus" && <MessageStrip docId={"06About"} />}
    <div key={pathId}>
      {content && <HtmlReader htmlContent={content.body} editorId={`general-${path}`} />}
    </div>
  </ScreenContainer>
}

export default GeneralScreen;
