import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function BackButton({text = "Return", className = "", to = "return"}) {
  const navigate = useNavigate()
  const {search} = useLocation()
  const query = new URLSearchParams(search);
  const returnTo = query.get("return_to")

  const handleClick = () => {
    returnTo ? navigate(`/${returnTo}`) : to === "return" ? navigate(-1) : navigate(to)
  }

  return (
    <button className={"hide-on-print scbtn--back " + (className)} type={"button"} onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} /> {text}
    </button>
  );
}

export default BackButton;
