import React, {useContext, useEffect} from 'react';
import P1_AmountBtns from "./P1_AmountBtns";
import {DonorFormContext} from "../../../../providers/DonorFormContext";
import Checkbox from "../../../elements/Checkbox";
import {Collapse} from "react-bootstrap";
import Textarea from "../../../elements/Texarea";

function P1TabContent({title, cta, type}) {
    const {form, handleChange} = useContext(DonorFormContext)

    useEffect(() => {
        if (form.customAmountChecked) handleChange(0, "selectedAmount")
        if (parseInt(form.selectedAmount) > 0) handleChange(false, "customAmountChecked")

    }, [form.customAmountChecked, form.selectedAmount])

    const GiftAidLink = ({index}) => {
        const links = [
            {href: "https://www.swiftaid.co.uk/info", label: "Swiftaid"},
            {href: "https://www.swiftaid.co.uk/legal/terms", label: "Terms"},
            {href: "https://www.swiftaid.co.uk/legal/privacy", label: "Privacy Policy"},]
        return <a className={"form-check-label__no-box line-height-tight donor-form__gift-aid-link"} href={links[index].href} target={"_blank"}
                  rel={"noreferrer"}>{links[index].label}</a>
    }

    return (
        <>
            <h6 className={"mb-0 mt-3 line-height-tighter"}>{title}</h6>
            <div className={"donor-form__bottom-margin-spacer"}>{cta}</div>

            {/* AMOUNT */}
            <div className={"donor-form__bottom-margin-spacer"}>
                <P1_AmountBtns type={type} />
            </div>
            {/* USER COMMENT */}
            <div className={"donor-form__bottom-margin-spacer"}>
                <Checkbox checked={form.includeCommentChecked} handleChange={v => handleChange(v, "includeCommentChecked")}
                          label={"Write us a comment"} />
                <Collapse in={form.includeCommentChecked}>
                    <div className={"m-0 p-0"}>
                        <Textarea handleChange={handleChange} keyToUpdate={"comment"} placeholder={"Your Comment"} rows={2}
                                  value={form.comment}
                                  containerClassName={"mt-2"} />
                    </div>
                </Collapse>
            </div>

            {/* GIFT AID */}

            <Checkbox checked={form.agreedToGiftAidChecked}
                      handleChange={v => handleChange(v, "agreedToGiftAidChecked")}
                      className={"mr-0 mb-2"}
                      label={<img src={"./images/libraries/donorForm/gift-aid-logo.png"} alt={"Gift Aid Logo"}
                                  className={"donor-form__gift-aid-logo"} />} />
            <p className={"fw-bold line-height-tighter text-smaller mb-2"}>Boost your donations by 25% at no cost to you!</p>

            <Collapse in={form.agreedToGiftAidChecked}>
                <div>
                    <p className={"form-check-label__no-box line-height-tight mb-0"} style={{cursor: "inherit"}}>
                        By clicking 'Next' I confirm that I am a UK taxpayer and that I understand Skipton Camerata have partnered
                        with <GiftAidLink index={0} /> who will use my information to identify and add Gift Aid on donations I make this tax year. I
                        have read Swiftaid's <GiftAidLink index={1} /> and <GiftAidLink index={2} /> and I authorise Swiftaid to add Gift Aid
                        on my behalf during this tax year.
                    </p>
                    <p className={"text-smallest line-height-tight mb-0"}>I understand that if I pay less Income Tax and/or Capital Gains Tax than the
                        amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.</p>
                </div>
            </Collapse>
        </>
    );
}


export default P1TabContent;