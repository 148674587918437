import React, {useContext, useEffect, useState} from 'react';
import HeaderLink from "./HeaderLink";
import {Collapse} from "react-bootstrap";
import {DarkContext} from "../../providers/DarkContext";
import {useLocation} from "react-router-dom";


function NavDesktop() {
    const [showMenu, setShowMenu] = useState(false)
    const [showProjects, setShowProjects] = useState(false)
    const [showEvents, setShowEvents] = useState(false)
    const {dark} = useContext(DarkContext)
    const pathname = useLocation().pathname

    useEffect(() => {
        setShowMenu(false)
        setShowEvents(false)
        setShowProjects(false)
    }, [pathname])

    return <>
        <div className={"vw-100 vh-100 position-absolute " + (!showMenu && "d-none")}
             style={{left: "-10vw", top: "0"}} onClick={() => setShowMenu(false)}>&nbsp;</div>
        <div className={"ml-auto mt-auto d-lg-none"}>
            {/*Burger menu*/}
            <div className={"navbar-burger"}>
                <button onClick={() => setShowMenu(!showMenu)}
                        className={"hamburger hamburger--squeeze " + (showMenu && "is-active")} type="button">
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
                </button>
            </div>

            {/*Nav*/}
            <Collapse in={showMenu}>
                <div className={"navbar__mobile-wrap " + (!dark ? "background-white" : "dark")}
                     style={{transition: "1s"}}>

                    <HeaderLink linkTitle={"Home"} linkTo={"/"} />

                    <a className={"navbar__link nav-link " + (dark && "dark__light-font")} style={{transition: "1s"}}
                       onClick={() => setShowEvents(!showEvents)}>What's On</a>
                    <div className={"navbar__drop-links " + (!showEvents ? "hide-menu" : "show-menu")}>
                        <HeaderLink linkTitle={"Upcoming Events"} linkTo={"/whats-on"} />
                        <HeaderLink linkTitle={"Past Events"} linkTo={"/past-events"} />
                    </div>

                    <a className={"navbar__link nav-link " + (dark && "dark__light-font")} style={{transition: "1s"}}
                       onClick={() => setShowProjects(!showProjects)}>Projects</a>
                    <div className={"navbar__drop-links " + (!showProjects ? "hide-menu" : "show-menu")}>
                        <HeaderLink linkTitle={"All Projects"} linkTo={"/projects"} />
                        <HeaderLink linkTitle={"Warriors of the Rainbow"} linkTo={"/projects/warriors-of-the-rainbow"} />
                        <HeaderLink linkTitle={"Baht 'At Variations"} linkTo={"/projects/baht-at-variations"} />
                        <HeaderLink linkTitle={"Lockdown Diaries"} linkTo={"/projects/lockdown-diaries"} />
                    </div>

                    <HeaderLink linkTitle={"News"} linkTo={"/news"} />

                    <HeaderLink linkTitle={"About Us"} linkTo={"/about-us"} />

                    <HeaderLink linkTitle={"Support Us"} linkTo={"/support-us"} />
                </div>
            </Collapse>
        </div>
    </>
}

export default NavDesktop;