import React, {useState} from 'react';
import {LinkContainer} from "react-router-bootstrap";
import PageTitle from "../components/elements/PageTitle";
import {Col, Row} from "react-bootstrap";
import {eventDateFormat} from "../helper";
import MessageStrip from "../components/blocks/MessageStrip";
import ScreenContainer from "../components/blocks/ScreenContainer";
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";

function PastEvents() {
    const [events, setEvents] = useState([])

    return <ScreenContainer>
        <FetchWithEndlessScroll currentPageDocs={events} setCurrentPageDocs={setEvents} type={"pastEvents"}>
            <PageTitle title={"Past Events"} />
            <MessageStrip docId={"03Past"} />

            {events && events.map((event, i) => {
                return <>

                    <Row className={"search-card"} key={i}>
                        <Col md={4} className="whats-on__img-wrap px-0 pr-md-4">
                            <LinkContainer to={`/whats-on/${event.id}-${event.title}`}>
                                <a><h2>{event.title}</h2></a>
                            </LinkContainer>
                            <h3>{event.subtitle}</h3>

                            <p className={"search-card__pe-venue"}>{event.venue}</p>
                            {event.eventDates && event.eventDates.map((date, i) => {
                                return <p key={i} className={"search-card__pe-date"}>{eventDateFormat(date)}</p>
                            })}

                        </Col>

                        <Col md={8} className={"px-0"}>
                            <div>
                                <p className={"search-card__details"}>{event.summary}</p>
                            </div>

                            <LinkContainer to={`/whats-on/${event.id}-${event.title}`}>
                                <a>Read More</a>
                            </LinkContainer>
                        </Col>
                    </Row>
                </>
            })}

        </FetchWithEndlessScroll>
    </ScreenContainer>
}

export default PastEvents;