import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";

function Checkbox({checked, handleChange, label = "", name = "", className = "", containerClassName = ""}) {
    // const [check, setCheck] = useState(checked);
    //
    // useEffect(() => {
    //     handleChange(check, name)
    // }, [check])

    return (
        <div className={containerClassName}>
            <button onClick={e => handleChange(!checked, name)} className={"btn-no-style " + (className)}>
                <Row className={"m-0"}>
                    <Col xs={"auto p-0"}>
                        <Form.Group className={"m-0 line-height-tight"}>
                            <Form.Check checked={checked}
                                        onChange={() => null}
                                        id={name}
                                        name={name}
                                        type={"checkbox"}
                                        label={""}
                                        defaultValue={checked}
                            />
                        </Form.Group>
                    </Col>
                    <Col className={"form-check-label__no-box line-height-tight mb-0 p-0"}>
                        <p className="form-check-label__no-box mb-0">{label}</p>
                    </Col>
                </Row>
            </button>
        </div>
    );
}

export default Checkbox;