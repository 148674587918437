import React from "react";
import {Col, Row} from "react-bootstrap";
import NewsDate from "../news/NewsDate";
import {Link} from "react-router-dom";

function SeeAlso({cards}) {

  if (!cards[0]) return null
  return (
    <>
      <hr />
      <h4 className={"mb-2"}>See Also...</h4>

      {cards?.map(card => {
        const type = card.object === "event" ? "whats-on" : card.object === "news" ? "news" : "projects"
        return <span key={card.id}>
          <Link to={type !== "projects" ? `/${type}/${card.id}-${card.title}` : `/${type}/${card.id}`}>

          <Row
            className={"w-100 mb-3 search-card p-0 mx-0 overflow-hidden " + (card.object === "project" ? "background-dark" : "background-prime")}>
            <Col sm={6} md={12} lg={6} className={"p-0 d-flex align-items-center"}>
              <img src={card.img[0]} alt={card.alt} className={"search-card__img h-100"} style={{maxHeight: "16rem"}} />
            </Col>
            <Col sm={6} md={12} lg={6} className={"py-4 overflow-hidden"} style={{maxHeight: "16rem"}}>


              {card.object === "news" &&
              <NewsDate postDate={card.dateCreated} className={"scNewsCard__header--date position-relative"} />}

              <h3 className={"white-colour mt-2 fw-bold"}>{card.title}</h3>
              <p className={"white-colour text-smaller line-height-tighter"} style={{opacity: ".85"}}>
                {card.summary?.substr(0, 65)}{card.summary?.length > 65 && "..."}
              </p>

            </Col>
          </Row>

          </Link>
        </span>
      })}
    </>
  );
}

export default SeeAlso;

