import React from 'react';
import ProjectTemplateOne from "../ProjectTemplateOne";

const data = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fwarriors-of-the-rainbow%2Fwater-carrier.jpg?alt=media&token=1b6eb751-3187-4881-a1e5-bffd1b2a3994",
    imgAlignX: "70%",
    imgAlignY: "40%",
    title: "Warriors of the Rainbow",
    quote: "They will be judged, not by empty words but by their actions and by their deeds.",
    vid: "https://www.youtube.com/embed/zpYJFmsKa-A?rel=0&modestbranding=1",
    li1: null,
    li2: null,
    credCentered: <ul>
        <li>Words & Music: Tim Brooks</li>
        <br/>
        <li>Greatwood Community Primary School Choir</li>
        <li>Water Street Community Primary School Choir</li>
        <li>Em Whitfield Brooks <em>chorus master</em></li>
        <br/>
        <li>Skipton Building Society Camerata</li>
        <li>Ben Crick <em>conductor</em></li>
        <br/>
        <li>Recorded live in Skipton Town Hall on Saturday 16th October 2021</li>
    </ul>,

    description: <div className={"pb-3 pb-sm-5"}>
        <p className="pb-0">Warriors of the Rainbow, for young voices and full orchestra, explores the subject of the
            environment and climate change from the perspective of the next generation - who will be the ones to
            potentially suffer from whatever challenges lie ahead.</p>

        <figure className="sub-composer-img mt-md-4 mb-3 clearfix" style={{top: "0"}}>
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fwarriors-of-the-rainbow%2Ftim-brooks.jpg?alt=media&token=6aed5dd7-7bf1-4d5b-8581-f3fb81483d62"
                 alt="Tom Brooks"/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Tim Brooks</p></figcaption>
        </figure>

        <p className="pb-0">The young voices represent this new generation - 'the voice of youth' - whilst pitted
            against the orchestra representing the onward march of 'progress', chaos and problems - until the end of the
            piece where the orchestral forces are much reduced in a more uplifting chorale which gives hope for the
            future.
        </p>

        <p className="pb-0">The text is a combination of original words by the composer and 'sound bites' of the Swedish
            activist, Greta Thunberg as well as by the young singers themselves. It also draws upon a prophesy
            attributed (wrongly, it now appears) to Hopi Native American folklore which are nonetheless relevant to our
            time.</p>

        <p className={"pb-0 text-right mb-0 mr-sm-3"}>Tim Brooks</p>

        <p className={"mt-5"}>"We are young and we are the ones who are going to have to live with this in the future."
            (Greta
            Thunberg)</p>

        <div className={"mt-4"}>
            <div className={"text-left pl-0 pl-sm-4 pl-md-5"}>
                <p className={"mb-0"}>I am the tiger, I am the elephant,</p>
                <p className={"mb-0"}>I am the eagle, I am the dolphin,</p>
                <p className={"mb-0"}>I am the whale,</p>
                <p className={"mb-0"}>I am the forest, I am the glacier,</p>
                <p className={"mb-0"}>I am the mountain, I am the ocean,</p>
                <p className={"mb-0"}>We are for sale.</p>
                <p className={"mb-0"}>We are standing here before you now,</p>
                <p className={"mb-0"}>You have nothing left to say.</p>
                <p className={"mb-0"}>What's the price you put upon our head</p>
                <p className={""}>To see a new day?</p>

                <p className={"mb-0"}>You are the pipeline, you are petroleum,</p>
                <p className={"mb-0"}>You are the factory, you are the oil slick,</p>
                <p className={"mb-0"}>You are pollution,</p>
                <p className={"mb-0"}>You are the tanker, you are the aeroplane,</p>
                <p className={"mb-0"}>You are the progress, you are the problem,</p>
                <p className={""}>Not the solution.</p>

                <p className={"mb-0"}>You are standing here before us now,</p>
                <p className={"mb-0"}>We have nothing more to say.</p>
                <p className={"mb-0"}>You have no idea of what you've done</p>
                <p className={""}>As time fades away.</p>

                <p className={"mb-5"}>(Words by Tim Brooks)</p>


                <p className={"mb-0"}>When all the Earth is ravaged</p>
                <p className={"mb-0"}>And all that lived is dying</p>
                <p className={"mb-0"}>And silence falls around us,</p>
                <p className={""}>Who will hear the crying?</p>

                <p className={"mb-0"}>A new tribe of people shall come unto the Earth</p>
                <p className={"mb-0"}>From many colours, from many creeds.</p>
                <p className={"mb-0"}>They will be judged, not by empty words</p>
                <p className={""}>But by their actions and by their deeds.</p>

                <p className={"mb-0"}>They shall make the Earth green again,</p>
                <p className={"mb-0"}>They shall make the Earth whole,</p>
                <p className={"mb-0"}>They shall make the sky blue again,</p>
                <p className={""}>They shall be know as Warriors of the Rainbow.</p>

                <p className={"mb-0"} style={{maxWidth: "450px"}}>(Words attributed to the Hopi Native Americans adapted
                    by Tim Brooks)</p>
            </div>
        </div>
    </div>,
}

function WarriorsOfTheRainbow() {
    return <>
        <ProjectTemplateOne data={data}/>
    </>
}

export default WarriorsOfTheRainbow;