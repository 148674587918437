import React, {useState} from "react";
import PageTitle from "../components/elements/PageTitle";
import NewsletterSignup from "../components/blocks/NewsletterSignup";
import ContactForm from "../components/blocks/ContactForm";
import ScreenContainer from "../components/blocks/ScreenContainer";
import MessageStrip from "../components/blocks/MessageStrip";
import HtmlReader from "../components/blocks/HtmlReader";
import FetchContent from "../dataManagement/FetchContent";


function Contact() {
  const [content, setContent] = useState(null)

  if (!content) return <FetchContent collection={"general"} docId={"contactPage"} setData={setContent} />
  return <ScreenContainer>
    <PageTitle title={"Contact Us"} />

    <MessageStrip docId={"08Contact"} />

    {content && content.content &&
    <HtmlReader htmlContent={content.content.info} classNames={""} editorId={"contact-info"} />}

    {content && content.content &&
    <ContactForm emailTo={content.content.emailTo} editorId={"contact-mailTo"} />}

    <NewsletterSignup />

  </ScreenContainer>
}

export default Contact;
