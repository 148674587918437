import React, {useState} from "react";
import queryString from "query-string";
import {Spinner} from "react-bootstrap";
import Input from "../elements/Input";
import {functions} from "../../dataManagement/firebase/firebaseConfig";
import {httpsCallable} from "firebase/functions";

function NewsletterSignup() {
  const [form, setForm] = useState({email: "", fName: "", lName: "", tags: []})
  const [status, setStatus] = useState({pending: false, err: false})
  const [msg, setMsg] = useState("")

  const handleChange = (v, k) => {
    setForm(prevState => ({...prevState, [k]: v}))
  }

  const subscribe = () => {
    setMsg("")
    setStatus({pending: true, err: false, success: false})

    const subscribe = httpsCallable(functions, "mail_subscribeNewMember");

    subscribe(form)
      .then(res => {
        console.log(res.data)
        if (res.data.error && res.data.message) {
          setStatus({pending: false, err: true})
          setMsg(res.data.message)
        } else if (!res && !res.data) {
          setStatus({pending: false, err: true})
          setMsg(`Something went wrong, please try again.`)
        } else {
          setStatus({pending: false, err: false})
          setMsg(res.data.message)
        }
      });
  }

  return <div className={"container"}>
    <h3 className="sc-newsletterText">Sign up to our newsletter</h3>

    <form onSubmit={formData => {
      formData.preventDefault()
      subscribe()
    }} className="sc-mailContainer">
      <p className="sc-underEmailText">For information on upcoming events and news, subscribe to our mailing list. (We
        never share emails with
        anyone else and you can opt out at any time.)</p>

      <div className={"d-sm-flex"}>
        <Input placeholder={"First Name"} keyToUpdate={"fName"} value={form.fName} containerClassName={"w-100 mb-1"}
               looseMb4
               handleChange={handleChange} required />
        <div style={{width: "4px"}} />
        <Input placeholder={"Last Name"} keyToUpdate={"lName"} value={form.lName} containerClassName={"w-100 mb-1"}
               looseMb4
               handleChange={handleChange} required />
      </div>
      <Input placeholder={"Email Address"} keyToUpdate={"email"} value={form.email} handleChange={handleChange}
             required />
      <div className={"d-flex"}>
        <button type="submit" className="scbtn--1 scbtn--med mt-2"
          // disabled={status.pending}
                style={{minWidth: "11.3rem"}}>
          {status.pending ? <Spinner animation={"border"} /> : "Subscribe"}
        </button>
        <p
          className={"ml-auto mt-1 text-right text-small font-weight-bold line-height-tight pl-2 " + (!status.err ? "text-success" : "text-danger")}>{msg}</p>
      </div>
    </form>


  </div>
}

export default NewsletterSignup;
