import React, {useContext} from 'react';
import {a} from "react-router-dom";
import {DarkContext} from "../../providers/DarkContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faYoutube,
    faTiktok,
    faPinterest,
    faSoundcloud
} from "@fortawesome/free-brands-svg-icons";

function SocialBar({icons}) {
    let {dark} = useContext(DarkContext)
    return <>
        {dark && <hr style={{backgroundColor: "#222"}}/>}
        <div className={"background-light-grey"}>
            < div className="container d-flex align-items-center">
                <p className={"sc-socialBarText " + (dark && "dark__light-font")}>Follow Us:</p>
                <div className={"d-flex"}>
                    {icons && icons.map((icon, i) => {
                        return <span key={i}>
                            {icon.icon === "fb" ?
                                <a href={icon.link} rel="noreferrer" target={"_blank"}
                                   className={"social-icon social-icon__fb " + (dark && "dark__light-font hover-prime")}>
                                    <FontAwesomeIcon icon={faFacebookF}/></a> :
                                icon.icon === "yt" ? <a href={icon.link} rel="noreferrer" target={"_blank"}
                                                        className={"social-icon social-icon__yt " + (dark && "dark__light-font hover-prime")}>
                                        <FontAwesomeIcon icon={faYoutube}/></a> :
                                    icon.icon === "tw" ?
                                        <a href={icon.link} rel="noreferrer" target={"_blank"}
                                           className={"social-icon " + (dark && "dark__light-font hover-prime")}>
                                            <FontAwesomeIcon icon={faTwitter}/></a> :
                                        icon.icon === "in" ?
                                            <a href={icon.link} rel="noreferrer" target={"_blank"}
                                               className={"social-icon " + (dark && "dark__light-font hover-prime")}>
                                                <FontAwesomeIcon icon={faInstagram}/></a> :
                                            icon.icon === "pi" ?
                                                <a href={icon.link} rel="noreferrer" target={"_blank"}
                                                   className={"social-icon " + (dark && "dark__light-font hover-prime")}>
                                                    <FontAwesomeIcon icon={faPinterest}/></a> :
                                                icon.icon === "tt" ?
                                                    <a href={icon.link} rel="noreferrer" target={"_blank"}
                                                       className={"social-icon " + (dark && "dark__light-font hover-prime")}>
                                                        <FontAwesomeIcon icon={faTiktok}/></a> :
                                                    icon.icon === "sn" ?
                                                        <a href={icon.link} rel="noreferrer" target={"_blank"}
                                                           className={"social-icon " + (dark && "dark__light-font hover-prime")}>
                                                            <FontAwesomeIcon icon={faSoundcloud}/></a> :

                                                        null}
                        </span>
                    })}
                </div>
            </div>
        </div>
    </>
}

export default SocialBar;