import React, {useContext, useEffect, useState} from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {DonorFormContext} from "../../../../providers/DonorFormContext";
import Loader from "../../../../dataManagement/components/Loader";
import {toTitleCase} from "../../../../helper";
import {db, functions} from "../../../../dataManagement/firebase/firebaseConfig";
import {httpsCallable} from "firebase/functions";
import {Collapse} from "react-bootstrap";

export default function P3_PaymentIntentCheckoutForm({setPageNum, dbAuthCode}) {
  const {form, formatAmount, customer} = useContext(DonorFormContext)
  const amount = formatAmount(form.customAmountChecked ? form.customAmount : form.selectedAmount)
  const frequency = toTitleCase(form.frequency.replaceAll("_", " "))
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret)
      .then(({paymentIntent}) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Payment setup was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      })
  }, [stripe]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsLoading(true);

    const setSupporter = httpsCallable(functions, "addToDb")
    await setSupporter({
      doc: {...customer, ...form},
      docId: customer.id,
      collection: "supporters",
      dbAuthCode,
    })

    const data = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "http://localhost:3001/support-us",
        payment_method_data: {
          billing_details: {
            name: `${form.fName} ${form.lName}`,
            email: form.email,
            address: form.address,
            phone: "",
          }
        },
      },
    });

    setMessage("")

    await db.collection("supporter_logs").doc(customer.id)
      .set({
        [form.formCreated_UTC]: {
          form,
          customer,
          dbAuthCode,
          paymentData: data,
          paid: data.paymentIntent && data.paymentIntent.id,
          triedPaying: true,
          shownConfirmation: false
        }
      }, {merge: true})

    if (data?.error?.type === "card_error" || data?.error?.type === "validation_error") {
      setMessage(data.error.message);
      setIsLoading(false)
    } else if (data?.paymentIntent?.id) {
      // Sort form data before sending to server
      const {selectedAmount, customAmount, ...wantedData} = form
      const finalWantedData = {
        ...wantedData,
        amount: form.customAmountChecked ? form.customAmount : form.selectedAmount
      }
      const paymentConfirm = httpsCallable(functions, "handlePaymentConfirmation")
      const response = await paymentConfirm({paymentData: data, form: finalWantedData, customer})

      if (response?.data?.status === 200) {
        setPageNum(num => num + 1)
      } else {
        setMessage("An unexpected error occurred.");
        setIsLoading(false);
      }
    }
  }


  const paymentElOptions = {
    redirect: "if_required",
    fields: {
      billingDetails: "never"
    },
  }


  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElOptions} />

        <div className={"donor-form__bottom-margin-spacer"} />
        <div className={"donor-form__footer px-0"}>
          <button className={"scBtn scbtn--2 bg-success border-success scbtn--med w-100 "}
            // onClick={handleSubmitPayment}
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
          >
            {isLoading ? <Loader spinner /> : `Donate £${amount} - ${frequency}`}
          </button>
        </div>
        {/* Show any error or success messages */}
        <div className={!!message ? "mb-3" : ""}>
          <Collapse in={!!message && !isLoading}>
            <div>
              <div className={"p-3 alert-danger border-danger border-thin border"}>
                <p className={"text-danger m-0 line-height-tight text-small fw-bold"} id="payment-message">
                  {message?.includes("incorrect") ||
                   message?.includes("incomplete") ||
                   message?.includes("is in the past") ||
                   message?.includes("invalid") ?
                   "Woops!" : "Sorry!"}
                </p>
                <p className={"text-danger m-0 line-height-tight text-small"} id="payment-message">{message}</p>
              </div>
            </div>
          </Collapse>
        </div>
      </form>
    </>
  )
    ;
}

