import React, {useEffect, useState} from "react";

// optionList = [{value:"foo", label:"Foo"}]

function Dropdown({
                    label = null,
                    placeholder = null,
                    optionList = [],
                    keyToUpdate,
                    handleChange,
                    value,
                    className = "",
                    containerClassName = "",
                    handleBlur = null,
                    handleFocus = null,
                    defaultSelectionText = null,
                    doNotSortOrder = false,
                  }) {
  const [showStatus, setShowStatus] = useState("HIDDEN");
  const [title, setTitle] = useState(placeholder);

  useEffect(() => {
    if (showStatus === "HIDING") {
      setTimeout(() => {
        setShowStatus("HIDDEN")
      }, 300)
    }
  }, [showStatus])

  useEffect(() => {
    let t = optionList.filter(x => x.value === value)[0]?.label
    if (t) setTitle(t)
    else setTitle(placeholder || "")
  }, [value])


  return <div className={containerClassName}>

    {/* LABEL */}
    {label && <label className={"general-input__label"}>{label}</label>}

    {/* DROPDOWN */}

    <div
      className={`wrapper-dropdown-2 ${className} ` + (showStatus === "HIDING" ? "drop-hiding" : showStatus === "SHOW" ? "drop-show" : "")}
      onClick={() => setShowStatus((showStatus === "HIDDEN" ? "SHOW" : "HIDING"))}
      onMouseLeave={() => setShowStatus("HIDING")}
      onBlur={e => handleBlur ? handleBlur(e) : () => null}
      onFocus={e => handleFocus ? handleFocus(e) : () => null}
    >
      <p className={"m-0"}>{title}</p>
      <ul className="dropdown">
        <hr className={"m-0 p-0"} />

        {[...optionList].sort((a, b) => {
          return doNotSortOrder ? null : a.label > b.label ? 1 : -1
        }).map((option, i) => {
          if (option.value === "hr") return <hr className={"m-0 p-0"} />
          return <li key={option.label}>
             <span className={"dropdown__span"}
                   onClick={() => handleChange(option.value ? option.value : option.label, keyToUpdate)}>
               <p className={"m-0"}>{option.label}</p>
            </span>
          </li>
        })}
      </ul>
    </div>

  </div>
}

export default Dropdown;