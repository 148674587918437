import React from 'react';
import {Link} from "react-router-dom";
import {eventDateFormat} from "../../helper";


function HomeTwoEventCards({cards}) {
    return <section className="container">
        <div id="topEvents" className="">
            <div className="sc-eventCardContainer">
                {cards.map((card, i) => {
                    return <div className={"sc-homeEventCard " + (i === 0 ? "pr-md-2 mb-4" : "pl-md-2 mb-3")}>
                        <Link key={i} to={`/whats-on/${card.id}-${card.title}`}>
                            <div className={" card two-card-wrap"}>
                                <img src={card.img} className={"card-img"} alt={card.alt} />
                                <div className={"sc-cardText-wrap"}>
                                    <h2><span>{card.title}</span></h2>
                                    <h3 className={"sc-cardSub"}><span>{card.subTitle}</span></h3>
                                    {card.eventDates && card.eventDates.map((date, i) => {
                                        return <p key={i} className={"search-card__wo-date"}>{eventDateFormat(date)}</p>
                                    })}
                                    <p className={"sc-cardDetails whats-on__venue"}>{card.venue}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                })}
            </div>
        </div>
    </section>
}

export default HomeTwoEventCards;