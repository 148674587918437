import React from 'react';
import {Link} from "react-router-dom";
import {Col, Row} from "react-bootstrap";

function SearchCardBlueprint({href, img, imgAlt, dark = false, children}) {
    return <>
        <Row className={"search-card " + (dark && "search-card__dark")}>
            <Col md={4} className="px-0 pr-md-4">
                <Link to={href}>
                    <img className="search-card__img" src={img} alt={imgAlt}/>
                </Link>
            </Col>

            <Col md={8} className={"px-0 pt-3 pt-md-0"}>
                {children}
            </Col>
        </Row>
    </>
}

export default SearchCardBlueprint;