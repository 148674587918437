import React from 'react';
import ProjectTemplateOne from "../ProjectTemplateOne";

const data = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2Fbaht-at-variations.jpeg?alt=media&token=5bc4baaf-7e87-4efe-9744-63311ab7fdfe",
    imgAlignX: "50%",
    imgAlignY: "50%",
    title: "Baht 'At Variations",
    quote: "Do I need a hat on? Do I heck!",
    vid: "https://www.youtube.com/embed/9fi3FYlJFSA?rel=0&modestbranding=1",
    li1: <ul>
        <li>Words</li>
        <li>Music</li>
        <br/>
        <li>Narrator</li>
        <li>Narrator</li>
        <br/>
        <li>Produced by</li>
        <li>Leader</li>
        <li>Conductor</li>
    </ul>,
    li2: <ul>
        <li>Ian McMillan</li>
        <li>Ben Crick</li>
        <br/>
        <li>Ian McMillan</li>
        <li>Claire-Marie Seddon</li>
        <br/>
        <li>Skipton Building Society Camerata</li>
        <li>Sophie Rosa</li>
        <li>Ben Crick</li>
    </ul>,
    credCentered: null,
    description: <div className={"pb-3 pb-sm-5"}>
        <figure className="sub-composer-img d-none d-md-block">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2FAdrian-Mealing-palm.jpg?alt=media&token=6ae9a333-464e-46d6-9bd5-dec9e3ab5d15"
                 alt="Ian McMillan"/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Ian McMillan</p></figcaption>
        </figure>
        <p className="pb-0">The <em>Baht ’At Variations</em> address one very important question: what was that bloke
            doing on Ilkley Moor in the cold without a hat? Having spent many hours walking, fell running and climbing
            on Ilkey Moor I can assure you that a hat is a vital piece of kit for keeping body and soul together and
            preventing you becoming food for the worms, the ducks and ultimately your mates.</p>
        <div className="d-none d-md-inline float-right">
            <figure className="sub-composer-img">
                <img style={{width: "22rem"}}
                     src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2Fben.jpg?alt=media&token=745492ac-e88d-4a8f-afe3-0b28bc8571df"
                     alt="Ben Crick"/>
                <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Ben Crick</p></figcaption>
            </figure>
        </div>
        <figure className="d-md-none sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2FAdrian-Mealing-palm.jpg?alt=media&token=6ae9a333-464e-46d6-9bd5-dec9e3ab5d15"
                 alt="Ian McMillan"/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Ian McMillan</p></figcaption>
        </figure>
        <p className="py-0">It was Ian McMillan's idea to dig into the backstory of the song <em>On Ilkla Mooar baht
            ’at</em> and to imagine what happened between its cautious but sincere protagonist and the wild and
            fun-loving Mary Jane. It was a collaborative exploration I relished.</p>
        <p className="py-0">The music reflects the story’s journey, moving from a cold, sparse moorland to the warm,
            impassioned call of love, with recklessly driven rhythms and moments of tender reflection. It is ‘northern’
            music without descending to pastiche or a tongue-in-cheek snigger at a Yorkshire stereotype. This is music
            inspired by the natural lilt and timings of the Yorkshire dialect in which Ian writes and which has
            surrounded me all my life.</p>
        <figure className="d-md-none sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fbaht-at-variations%2Fben.jpg?alt=media&token=745492ac-e88d-4a8f-afe3-0b28bc8571df"
                 alt="Ben Crick"/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Ben Crick</p></figcaption>
        </figure>
        <p className="py-0">The <em>Baht ’At Variations</em> explore those real human emotions that regularly motivate
            behaviour which to the outsider often seem ridiculous. I think we can all relate to that.</p>
        <br/>
        <p className="py-0 text-right">- Ben Crick, composer & conductor</p>
    </div>
    ,
}

function BahtAtVariations() {
    return <>
        <ProjectTemplateOne data={data}/>
    </>
}

export default BahtAtVariations;