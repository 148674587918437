import {db} from "../firebase/firebaseConfig";
import {collection, getDocs, query, where, orderBy, limit, startAfter} from "firebase/firestore";
import moment from "moment-timezone";
import {ENDLESS_SCROLL_CONFIG, DOC_FETCH_QUANTITY} from "./endlessScrollConstants";

const endlessScrollConfig = ENDLESS_SCROLL_CONFIG


// Events
export const eventsFetch = (startAft) => {
  console.log("HI")
  if (startAft) {
    return getDocs(query(collection(db, "events"),
      where("online", "==", true),
      where("expireToPast", ">", moment().tz("Europe/London").format()),
      orderBy(endlessScrollConfig.events.orderBy, "asc"),
      startAfter(startAft),
      limit(DOC_FETCH_QUANTITY)
    ))
  } else {
    return getDocs(query(collection(db, "events"),
      where("online", "==", true),
      where("expireToPast", ">", moment().tz("Europe/London").format()),
      orderBy(endlessScrollConfig.events.orderBy, "asc"),
      limit(DOC_FETCH_QUANTITY)
    ))
  }
}

// Past Events
export const pastEventsFetch = (startAft) => {
  if (startAft) {
    return getDocs(query(collection(db, "events"),
      where("online", "==", true),
      where("expireToPast", "<", moment().tz("Europe/London").format()),
      orderBy(endlessScrollConfig.pastEvents.orderBy, "desc"),
      startAfter(startAft),
      limit(DOC_FETCH_QUANTITY)
    ))
  } else {
    return getDocs(query(collection(db, "events"),
      where("online", "==", true),
      where("expireToPast", "<", moment().tz("Europe/London").format()),
      orderBy(endlessScrollConfig.pastEvents.orderBy, "desc"),
      limit(DOC_FETCH_QUANTITY)
    ))
  }
}

//News
export const newsFetch = (startAft) => {
  if (startAft) {
    return getDocs(query(collection(db, "news"),
      where("online", "==", true),
      orderBy(endlessScrollConfig.news.orderBy, "desc"),
      startAfter(startAft),
      limit(DOC_FETCH_QUANTITY)
    ))
  } else {
    return getDocs(query(collection(db, "news"),
      where("online", "==", true),
      orderBy(endlessScrollConfig.news.orderBy, "desc"),
      limit(DOC_FETCH_QUANTITY)
    ))
  }
}
