export const frontline = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FFrontline%20Worker.jpg?alt=media&token=33d59bd7-6ede-4429-8eef-b3ab9fe9b94c",
    imgAlignX: "35%",
    imgAlignY: "50%",
    title: "The Frontline Worker",
    quote: "Perhaps I shouldn't have left my day job to try and become a stand-up comedian.",
    vid: "https://www.youtube.com/embed/l6u618B7kbs?rel=0&modestbranding=1",
    li1: <ul>
        <li>Music composed by</li>
        <li>Script by</li>
        <br/>
        <li>Narrator</li>
        <br/>
        <li>Violin</li>
        <li>Double bass</li>
        <li>Clarinet</li>
        <li>Bassoon</li>
        <li>Trumpet</li>
        <li>Trombone</li>
        <li>Percussion</li>
        <br/>
        <li>Conducted by</li>
        <br/>
        <li>Digital design and production by</li>
    </ul>,
    li2: <ul>
        <li>Susannah Self</li>
        <li>Kamal Kaan</li>
        <br/>
        <li>Claire-Marie Seddon</li>
        <br/>
        <li>Sophie Rosa</li>
        <li>Richard Waldock</li>
        <li>Colin Blamey</li>
        <li>Charlotte Curwen</li>
        <li>Anthony Thompson</li>
        <li>Beth Calderbank</li>
        <li>Polly McMillan</li>
        <br/>
        <li>Ben Crick</li>
        <br/>
        <li>Cloth Ears</li>
    </ul>,
    credCentered: null,
    description: <div><p><em>This commission is so interesting because of its orchestration for actor/narrator and 7
        players and because of the intriguing script created by Kamal Kaan. The Frontline Worker which is a combination
        of stand-up comedy interfaced with the tragedy of a nurse faced with the lack of PPE and ventilators in the NHS
        during Lockdown. She becomes broken by watching patients die and subsequently leaves her job. There is a
        Kafkaesque quality to this scenario so I am glad the words will be spoken rather than sung, because the
        humanistic message is too important to be possibly lost on a vocal line. The orchestration is of course
        identical to Stravinsky’s The Soldier’s Tale but I am aiming for a more naturalistic. A trap in composing for
        narrator and ensemble is that the music can become too descriptive of the text and the piece ends up becoming
        parody of itself although I admit I use a whip and whistle! But parallel to the anarchic hilarity I want to
        convey the extremely pitiful situation that frontline workers have been forced into during the Covid crisis.
        Therefore there is thoughtful music in juxtaposition to the comedy.</em></p>
        <figure className="sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fcomposers%2FSusanna%20Self.JPG?alt=media&token=fc38d16b-50d8-42fd-83ab-d54fcf9d16c7"
                 alt={"Susanna Self"}/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Susanna Self</p></figcaption>
        </figure>
        <p>Dr Susannah Self ARCM, MPhil Cantab studied composition and singing at The Royal College of Music and
            Cambridge University. She has just completed her PhD in composition on a scholarship at The Royal Birmingham
            Conservatoire. Recent commissions include: Fast: Spitalfields Festival 2020, Wave Form: Royal Birmingham
            Ballet 2019, Quilt Song: Birmingham Old Rep 2018, SLAP: Birmingham Contemporary Music Group 2018, Freedom
            Bridge: Birmingham Opera Company 2017, The Butt: Musictheatertage, Vienna 2016, Tardebigge Song Festival
            2013, The Boy from Brazil: Tête a Tête, 2012, Heroic Women: Royal Opera House, Garden Venture, 1992.</p>
        <p>Dr Self has sung as a mezzo soloist in many opera houses in particular for Opéra du Rhin in Strasbourg, The
            Vlaamse Opera in Antwerp and Ghent and The Landestheater in Salzburg. Dr Self has run composition workshops
            for Nottingham University and facilitated music programmes for Skyros, Esalen and Dartington Summer School.
            She teaches composition at Junior Guildhall and her publisher is Composers Edition. </p>
    </div>,
    prev:
        {
            id: null,
            title: null,
        },
    next:
        {
            id: "a",
            title: "The Anti-masker"
        }
}

export const antiMasker = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FAnti-masker.jpg?alt=media&token=f1547279-2333-4287-8e78-3ede9ea2c8f6",
    imgAlignX: "68%",
    imgAlignY: "50%",
    title: "The Anti-masker",
    quote: "I am wearing a facemask. What? It’s not the right one? Well baaa off ya sheep.",
    vid: "https://www.youtube.com/embed/n_BSwg0pLI0?rel=0&modestbranding=1",
    li1: <ul>
        <li>Music composed by</li>
        <li>Script by</li>
        <br/>
        <li>Narrator</li>
        <br/>
        <li>Violin</li>
        <li>Double bass</li>
        <li>Clarinet</li>
        <li>Bassoon</li>
        <li>Trumpet</li>
        <li>Trombone</li>
        <li>Percussion</li>
        <br/>
        <li>Conducted by</li>
        <br/>
        <li>Digital design and production by</li>
    </ul>,
    li2: <ul>
        <li>Nicholas Olsen</li>
        <li>Kamal Kaan</li>
        <br/>
        <li>Claire-Marie Seddon</li>
        <br/>
        <li>Sophie Rosa</li>
        <li>Richard Waldock</li>
        <li>Colin Blamey</li>
        <li>Charlotte Curwen</li>
        <li>Anthony Thompson</li>
        <li>Beth Calderbank</li>
        <li>Polly McMillan</li>
        <br/>
        <li>Ben Crick</li>
        <br/>
        <li>Cloth Ears</li>
    </ul>,
    credCentered: null,
    description: <div>
        <p><em>While composing The Anti-Masker I was struck by the powerful expression and character that Kamal Kaan
            created in his text. I was keen to use that as the initial impetus of the piece. I started by considering
            the narrator’s role within the ensemble and cherrypicked quotes from Kaan’s text to emphasise the bombastic
            nature of the character. It was after this process that I started to create a musical space for an
            in-your-face ranting individual whose anti-masking story is told over six or so minutes with elements of
            musical rhetoric and reference underpinning their story. - Nicholas Olsen</em></p>
        <figure className="sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fcomposers%2FNicholas%20Olsen.jpeg?alt=media&token=f6d62019-48fc-48fb-9b36-3c5388bdef4a"
                 alt={"Nicholas Olsen"}/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Nicholas Olsen</p></figcaption>
        </figure>
        <p>Nicholas Olsen is a Scottish-based Welsh composer. He has worked with a number of internationally renowned
            ensembles and conductors from across the UK and further afield. These projects have included performances
            and collaborations with the Royal Scottish National Orchestra, BBC Scottish Symphony Orchestra, BBC National
            Orchestra of Wales, Ensemble Modern, RSNO Chorus and Junior Chorus, Psappha, and Red Note Ensembles. He is
            currently a doctoral researcher at the Royal Birmingham Conservatoire under the supervision of Joe Cutler,
            and Ed Bennett. In 2017, Nicholas was awarded the Principal’s Prize for All Round Excellent on graduating
            from the Royal Conservatoire of Scotland where he studied with Gordon McPherson and Stuart MacRae. </p>
        <p>Nicholas is also a committed teacher and has tutored composition in a variety of contexts; including at the
            Royal Conservatoire of Scotland's Composition Summer School and Junior Conservatoire, and at the United
            World College of the Atlantic. </p>
    </div>,
    prev:
        {
            id: "f",
            title: "The Frontline Worker",
        },
    next:
        {
            id: "s",
            title: "The Survivor"
        }
}

export const survivor = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FSurvivor.jpg?alt=media&token=cdd27c30-f568-41c7-bc9b-dfd19ac7cf5e",
    imgAlignX: "77%",
    imgAlignY: "50%",
    title: "The Survivor",
    quote: "Just when you think you’re not strong enough, you remember there’s still some things worth living for.",
    vid: "https://www.youtube.com/embed/mO86qdfMafw?rel=0&modestbranding=1",
    li1: <ul>
        <li>Music composed by</li>
        <li>Script by</li>
        <br/>
        <li>Narrator</li>
        <br/>
        <li>Violin</li>
        <li>Double bass</li>
        <li>Clarinet</li>
        <li>Bassoon</li>
        <li>Trumpet</li>
        <li>Trombone</li>
        <li>Percussion</li>
        <br/>
        <li>Conducted by</li>
        <br/>
        <li>Digital design and production by</li>
    </ul>,
    li2: <ul>
        <li>Jake Adams</li>
        <li>Kamal Kaan</li>
        <br/>
        <li>Rob Edwards</li>
        <br/>
        <li>Sophie Rosa</li>
        <li>Richard Waldock</li>
        <li>Colin Blamey</li>
        <li>Charlotte Curwen</li>
        <li>Anthony Thompson</li>
        <li>Beth Calderbank</li>
        <li>Polly McMillan</li>
        <br/>
        <li>Ben Crick</li>
        <br/>
        <li>Cloth Ears</li>
    </ul>,
    credCentered: null,
    description: <div>
        <p><em>The Survivor takes the story of a man who contracted COVID-19 in March 2020 and was put into a coma
            before eventually recovering.My main focus when composing this piece was to allow the text to be expressed
            clearly and to not be hindered by the music, as it is important to remember during this pandemic that it is
            not just statistics and economies being hurt, but also real people with real stories. As such the material
            and gestures of the ensemble are relatively simple, focusing more on slow moving lines and shifting
            harmonies than moments of drama.</em></p>
        <figure className="sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fcomposers%2FJake%20Adams.jpg?alt=media&token=530c3d15-63f0-456b-9227-e0fea3be7b63"
                 alt={"Jake Adams"}/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Jake Adams</p></figcaption>
        </figure>
        <p>Jake Adams is a composer, guitarist and ethnomusicologist from Brighouse, West Yorkshire. </p>
        <p>Jake completed his undergraduate degree at the University of York and studied composition within this time
            with Thomas Simaku, Roger Marsh and Daniel March. He then continued his studies at the University of York,
            graduating in 2019 with an MA in Music with distinction supervised by Thomas Simaku. He has received
            composition lessons from composers including Wolfgang Liebhart, Joanna Bailie, Yuri Kasparov and Oscar
            Bianchi. Jake's work primarily explores the field of cross-cultural composition, incorporating elements and
            drawing influence from musics of cultures outside of the Western classical tradition (with his main focus
            being the music of the Javanese gamelan and Japanese gagaku). </p>
        <p>Respect for musicians and cultures is key to Jake's work, and as such collaboration is a key factor within
            his practice. His work has been performed by soloists and ensembles including the Quatuor Diotima, the
            Chimera Ensemble, Gamelan Sekar Petak, Jasdeep Singh Degun and Dark Inventions.
            Jake is the founder and director of The Arc Project, a collaborative new music initiative based in the north
            of England.</p>
    </div>,
    prev:
        {
            id: "a",
            title: "The Anti-masker",
        },
    next:
        {
            id: "d",
            title: "The Death"
        }
}

export const death = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FDeath.jpg?alt=media&token=f6d8cc26-3738-42d1-a60e-f7c40d8e1a5f",
    imgAlignX: "40%",
    imgAlignY: "50%",
    title: "The Death",
    quote: "I was stood on top of a cliff and waiting for bad news to come push me off.",
    vid: "https://www.youtube.com/embed/gnTpDXji8vo?rel=0&modestbranding=1",
    li1: <ul>
        <li>Music composed by</li>
        <li>Script by</li>
        <br/>
        <li>Narrator</li>
        <br/>
        <li>Violin</li>
        <li>Double bass</li>
        <li>Clarinet</li>
        <li>Bassoon</li>
        <li>Trumpet</li>
        <li>Trombone</li>
        <li>Percussion</li>
        <br/>
        <li>Conducted by</li>
        <br/>
        <li>Digital design and production by</li>
    </ul>,
    li2: <ul>
        <li>Rania Chrysostomou</li>
        <li>Kamal Kaan</li>
        <br/>
        <li>Rob Edwards</li>
        <br/>
        <li>Sophie Rosa</li>
        <li>Richard Waldock</li>
        <li>Colin Blamey</li>
        <li>Charlotte Curwen</li>
        <li>Anthony Thompson</li>
        <li>Beth Calderbank</li>
        <li>Polly McMillan</li>
        <br/>
        <li>Ben Crick</li>
        <br/>
        <li>Cloth Ears</li>
    </ul>,
    credCentered: null,
    description: <div>
        <p><em>The script of “The Death” travels through a wide range of emotions, from love, to grief, worry, confusion
            and shock, fury, numbness, to end up with a form of acceptance and hope to the future. The music is mostly
            descriptive and linear, preceding the narrator’s line of events for a few moments. It attempts to amplify
            the narrator's story and be part of her psyche and mind, and by this diminish notions of hierarchy between
            music and narration. At times the trombone takes on the role of the father where he struggles to catch his
            breath or with his loving theme.</em></p>
        <figure className="sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fcomposers%2FRania%20Chrysostomou.jpg?alt=media&token=a1c34bb5-1012-49b0-b617-8b0fe81a16f9"
                 alt={"Rania Chrysostomou"}/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">Rania Chrysostomou</p></figcaption>
        </figure>
        <p>Rania Chrysostomou is a Cypriot composer living in London. Her music is the amalgamation of her life
            experiences and behavioural observations along with the combination of sounds of Cyprus and her environment.
            She studied at the Ionian University in Greece, and the University of York in England where she graduated
            with an MA in Composition. Her music has been performed in Europe, UK and USA, and she has collaborated with
            artists from different fields like dancers, visual artists, poets, actors, directors, and game
            designers. </p>
        <p>As a music educator she has worked with the early years and in primary schools, currently writing articles
            about early years education. Creating music with a narrator is a theme she continues to explore through her
            collaboration with Skipton Camerata and in her piece The Silver Lines, premiered by the Shadanga Duo in
            Denmark. </p>
    </div>,
    prev:
        {
            id: "s",
            title: "The Survivor",
        },
    next:
        {
            id: "o",
            title: "The Outsider"
        }
}

export const outsider = {
    img: "https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fsub-page-cards%2FOutsider.jpg?alt=media&token=663a4614-1106-4e9d-af8c-1d977eb13cec",
    imgAlignX: "55%",
    imgAlignY: "50%",
    title: "The Outsider",
    quote: "Apparently we have nine lives? You only one. Be good to it. Treat it kindly.",
    vid: "https://www.youtube.com/embed/aDEYfHdf7HQ?rel=0&modestbranding=1",
    li1: <ul>
        <li>Music composed by</li>
        <li>Script by</li>
        <br/>
        <li>Narrator</li>
        <br/>
        <li>Violin</li>
        <li>Double bass</li>
        <li>Clarinet</li>
        <li>Bassoon</li>
        <li>Trumpet</li>
        <li>Trombone</li>
        <li>Percussion</li>
        <br/>
        <li>Conducted by</li>
        <br/>
        <li>Digital design and production by</li>
    </ul>,
    li2: <ul>
        <li>John Kefala Kerr</li>
        <li>Kamal Kaan</li>
        <br/>
        <li>Claire-Marie Seddon</li>
        <br/>
        <li>Sophie Rosa</li>
        <li>Richard Waldock</li>
        <li>Colin Blamey</li>
        <li>Charlotte Curwen</li>
        <li>Anthony Thompson</li>
        <li>Beth Calderbank</li>
        <li>Polly McMillan</li>
        <br/>
        <li>Ben Crick</li>
        <br/>
        <li>Cloth Ears</li>
    </ul>,
    credCentered: null,
    description: <div>
        <p><em>My response to Kamal Kaan’s script was to draw out the impersonal creatureliness of the story’s feline
            narrator—its disapproving and supercilious nature (“cats have nine lives, you only one”) and glib attitude
            towards its owner’s death. The Greek folk dance known as the Zeibekiko, with its nine-beat phrasing,
            provides the musical ‘other’ here, as does a touch of dubstep and a nod in the direction of a certain West
            End musical. Together, these elements bring an intensity to the quirky depiction of the Covid lockdown, as
            viewed from the perspective of a domestic pet.</em></p>
        <figure className="sub-composer-img">
            <img style={{width: "100%"}}
                 src="https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Flockdown-diaries%2Fcomposers%2FJohn%20Kefala%20Kerr.jpg?alt=media&token=a3e4b76b-5ca6-4e4b-aef2-ea748cbc4b95"
                 alt={"John Kefala Kerr"}/>
            <figcaption style={{backgroundColor: "#111"}}><p className="p-1 m-0">John Kefala Kerr</p></figcaption>
        </figure>
        <p>John Kefala Kerr is a British-Greek composer, sound artist and writer. </p>
        <p>A prize-winning graduate of the Guildhall School of Music & Drama and the University of Sussex, John’s output
            includes instrumental, vocal, and multimedia works. These are often conceived in close proximity to everyday
            events, situations and circumstances. John has had work presented at festivals and venues in the UK, USA,
            Europe and Japan, including the Cathedral of St John the Divine, New York; Wigmore Hall; St John’s Smith
            Square; South Bank Centre, London; BALTIC Centre for Contemporary Art; Select 107 FM; Teatro Rossini,
            Pesaro; Atelier St Ann, Brussels and De Singel, Antwerp. </p>
        <p>John’s first novel Thimio’s House was published by Perfect Edge Books in 2013 and he has held residencies at
            Durham Cathedral, Dove Marine Laboratory, Grainger Market, UK National Railway Museum and National Centre
            for the Written Word.</p>

    </div>,
    prev:
        {
            id: "d",
            title: "The Death",
        },
    next:
        {
            id: null,
            title: null
        }
}

