import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {DonorFormContext} from "../../../providers/DonorFormContext";

function DonorFormPagination({totalPages, pageNum, setPageNum, allowNext, children}) {
  const {donorConstants: {PAGE_TITLES}} = useContext(DonorFormContext)

  const handleBtn = (val) => {
    if (val < 0 || val > totalPages - 1) return
    setPageNum(val)
  }

  return (
    <>
      <div className={"donor-form__header background-light-grey"}>
        <button className={"scbtn--back m-0 bg-transparent"} type={"button"} onClick={() => handleBtn(pageNum - 1)}>
          {pageNum !== 0 && pageNum !== 3 && <FontAwesomeIcon icon={faArrowLeft} />}
        </button>

        <div className={"donor-form__title"}>
          <h6 className={"line-height-tighter fw-normal"}>{PAGE_TITLES[pageNum]}</h6>
        </div>

        <div className={"d-flex justify-content-end align-items-center"}>

          {[...Array(totalPages)].map((_, i) => {
            return <div key={i}
                        className={"border-light-grey border-thin ml-2 " + (i === pageNum && "background-prime border-prime")}
                        style={{
                          height: "13px",
                          width: "13px",
                          borderRadius: "100%",
                          opacity: i !== pageNum ? ".6" : ".9"
                        }} />
          })}
        </div>
      </div>

      <div className={"donor-form__body " + (pageNum !== totalPages - 1 && "donor-form__bottom-margin-spacer")}>
        {children}
      </div>

      {pageNum < totalPages - 1 &&
      <div className={"d-flex justify-content-end donor-form__footer"}>
        <button className={"scBtn scbtn--2 scbtn--med w-100"}
                onClick={() => handleBtn(pageNum + 1)}
                disabled={!allowNext}>
          Next
        </button>
      </div>}

    </>
  );
}

export default DonorFormPagination;