import React from 'react';
import HeaderLink from "./HeaderLink";
import HeaderDropdown from "./HeaderDropdown";

function NavDesktop() {
    return <>
        <div className={"ml-auto navbar__desktop-wrap d-none d-lg-flex"}>

            <HeaderLink linkTitle={"Home"} linkTo={"/"} />

            <HeaderDropdown navTitle={"What's On"} navTitleLink={"whats-on"} links={[
                {title: "Past Events", link: "past-events"}
            ]} />

            <HeaderDropdown navTitle={"Projects"} navTitleLink={"projects"} links={[
                {title: "Warriors of the Rainbow", link: "projects/warriors-of-the-rainbow"},
                {title: "Baht 'At Variations", link: "projects/baht-at-variations"},
                {title: "Lockdown Diaries", link: "projects/lockdown-diaries"},
            ]} />

            <HeaderLink linkTitle={"News"} linkTo={"/news"} />

            <HeaderLink linkTitle={"About Us"} linkTo={"/about-us"} />

            <HeaderLink linkTitle={"Support Us"} linkTo={"/support-us"} />

        </div>
    </>
}

export default NavDesktop;