import React from 'react';
import CommentForm from "./CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "react-bootstrap";
import {updateData} from "../../../dataManagement/firebase/dbFunctions";

//todo: save commentsAndLikes to DB on submit
//todo: ensure comment is sanitised when submitting to db.
//todo: ?? save comment as cookie to later edit.delete ??

function Comments({postId, comments}) {

    // const deleteComment = (i) => {
    //     let tempArr = comments.reverse()
    //     tempArr.splice(i, 1)
    //     updateData("comments", tempArr.reverse(), postId, "likesComments")
    // }

    return <>
        <section className={"scComments"}>
            <CommentForm postId={postId} comments={comments}/>


            {/*Comments*/}
            {comments && <div id={"commentDiv"}>
                {comments.slice(0).reverse().map((comment, i) => {
                    return <div key={i} className={"scComments__displayComments"}>
                        <Row className={"scComments__displayComments--head px-0 mx-0"}>
                            <Col xs={11}>
                                <p className={"scComments__displayComments--name scComments__displayComments--name-date"}>
                                    {decodeURIComponent(comment.userName)}
                                </p>
                                <p className=" scComments__displayComments--date scComments__displayComments--name-date">
                                    {comment.commentDate}
                                </p>
                            </Col>
                            <Col xs={1} className={"text-right"}>
                                {/*<button className={"prime-colour border-0"} style={{fontSize: "1.3rem"}}*/}
                                {/*        onClick={() => deleteComment(i)}>*/}
                                {/*    <FontAwesomeIcon size={"lg"} icon={faTrashAlt}/>*/}
                                {/*</button>*/}
                            </Col>
                        </Row>
                        <p className={"scComments__displayComments--comment"}>
                            {decodeURIComponent(comment.userComment)}</p>
                    </div>
                })}
            </div>}
        </section>
    </>
}

export default Comments;
