import moment from "moment";
import {db} from "./dataManagement/firebase/firebaseConfig";
import {idMaker} from "./components/news/commentsAndLikes/commentsAndLikesHelper";

///// Create event Date, Time
export const eventDateFormat = (d) => {
  return moment(d).utc(false).format("dddd Do MMMM YYYY [at] h:mma")
}

///// Create news Date, Time
export const newsDateFormat = (d) => {
  return moment(d).utc(false).format("Do MMMM YYYY")
}

//Doc Title:
export const getDocTitle = (pathname, name) => {
  let page = pathname.includes("whats-on") ? "What's On" :
             pathname.includes("past-events") ? "Past Events" :
             pathname.includes("projects") ? "Projects" :
             pathname.includes("news") ? "News" :
             pathname.includes("about-us") ? "About Us" :
             pathname.includes("support-us") ? "Support Us" :
             pathname.includes("contact-us") ? "Contact Us" :
             pathname.includes("cookies") ? "Cookies" :
             pathname.includes("privacy-notice") ? "Privacy Notice" :
             pathname.includes("terms-of-use") ? "Terms Of Use" :
             null
  let n = []
  if (pathname.includes("whats-on/") || pathname.includes("past-events/") || pathname.includes("news/")) {
    n = pathname.split("-")
    n = n[n.length - 1]
  }
  return n[0] ? `SC | ${n}` : `${page ? `SC | ${page}` : "Skipton Camerata"}`
}


// Text To Title Case
export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}


// ** TRIGGER EMAIL ** //
// HTML for the email
export const email = (FNAME, EMAIL, MESSAGE) => `<body>
    <h1 style="font-weight: 900; color: #ff9506; margin-top: 20px; margin-bottom: 0;">
        <strong>Contact Form Message Received</strong>
     </h1>
    <div style="padding:10px 0; margin-top: 0;">
        <p style="margin-bottom: 0; font-weight: bold; font-size: 12pt">Name:</p>
        <p style="margin-top: 0; font-size: 12pt">${FNAME}</p>
        <p style="margin-bottom: 0; font-weight: bold; font-size: 12pt">Email:</p>
        <p style="margin-top: 0; font-size: 12pt">${EMAIL}</p>
        <p style="margin-bottom: 0; font-weight: bold; font-size: 12pt">Message:</p>
        <p style="margin-top: 0; font-size: 12pt">${MESSAGE}</p>
    </div>
    </body>`

// Trigger email //
// form: (from_name, email_address, message, subject)
// status: (sending, sent, err, errMsg)
export function triggerEmail(form, setForm, setStatus, recipientArr) {
  setStatus({sending: true, sent: false, err: false})
  if (form.user_name === "" || form.email_address === "" || form.message === "") {
    setStatus({sending: false, sent: false, err: true, errMsg: "Please complete all fields."})
  } else {
    // Create form ID
    const initial = form.user_name.charAt(0)
    const id = `contact-form_${idMaker()}-${initial}`

    // Send form to db (email_admin)
    db.collection("email_admin").doc(id).add({
      to: recipientArr.map(e => e.email),
      from: form.from,
      message: {
        subject: form.subject,
        html: email(form.user_name, form.email_address, form.message),
      },
    })
      .then(() => {
        setStatus({
          sending: false,
          sent: true,
          err: false,
          errMsg: "Thank you! We got your message."
        })
        setForm({...form, user_name: "", email_address: "", message: "",})
      })
      .catch(err => setStatus({
        sending: false,
        sent: false,
        err: true,
        errMsg: "Oops! Something went wrong. Please check your email address and try again."
      }))
  }
}
