import React, {useEffect, useState} from "react";
import ImOnScreen from "../../siteTools/ImOnScreen";

function SoundscapesParagraphs({setShowImageNumber}) {
  const [introOnScreen, setIntroOnScreen] = useState(false);
  const [soundOnScreen, setSoundOnScreen] = useState(false);
  const [imageOnScreen, setImageOnScreen] = useState(false);
  const [spaceOnScreen, setSpaceOnScreen] = useState(false);
  const [quoteOnScreen, setQuoteOnScreen] = useState(false);

  useEffect(() => {

    if (spaceOnScreen) {
      setShowImageNumber(4)
    } else if (imageOnScreen) {
      setShowImageNumber(3)
    } else if (soundOnScreen) {
      setShowImageNumber(2)
    } else if (introOnScreen) {
      setShowImageNumber(1)
    } else {
      setShowImageNumber(5)
    }

  }, [introOnScreen, soundOnScreen, imageOnScreen, spaceOnScreen, quoteOnScreen])

  return (
    <>
      <div className={"ss-paragraphs"}>

        <div
          className={"ss-paragraphs__sticky-wrap ss-para-0 " + (soundOnScreen || spaceOnScreen || quoteOnScreen ? " opacity-hide" : " opacity-show")}>
          <div className={"ss-paragraphs__sticky-child"}>
            <ImOnScreen setOnScreenState={[introOnScreen, setIntroOnScreen]} showLoader={false} />
            <p>“Music is an immersive art form that can describe a scene as eloquently as any other.”<br /><br />
              Soundscapes is an immersive audio-video installation created by projection artist Michaela
              French and composer Ben Crick showcasing the diverse scenery of the Yorkshire Dales.
              Projected inside a purpose-built hemispherical dome, Soundscapes combines 360°
              videography with a symphonic soundtrack inspired by the landscape.</p>
          </div>
        </div>

        <div className={"mt-5"}>
          <div
            className={"ss-paragraphs__sticky-wrap ss-para-1 " + (imageOnScreen || spaceOnScreen || quoteOnScreen ? " opacity-hide" : " opacity-show")}>
            <div className={"ss-paragraphs__sticky-child"}>
              <ImOnScreen setOnScreenState={[soundOnScreen, setSoundOnScreen]} showLoader={false} />
              <h2 className={""}>Sound</h2>
              <p className={""}>Soundscapes tells the story of a place, and its
                power to move. Skipton Camerata’s performance of Ben’s original compositions conjures the Yorkshire
                Dales;
                a hillside in summer, the banks of a river, the dark depths of the caves that lie beneath.
                The music is a direct response to places and points in time in the Dales. Since childhood, Ben’s
                been a caver, climber and walker. Now his music takes people to places they might not
                otherwise see, or to new ways of seeing and feeling them. This music is his artistic portrayal
                of a place, and how it felt to be there.</p>
            </div>
          </div>
        </div>

        <div
          className={"ss-paragraphs__sticky-wrap ss-para-2 " + (spaceOnScreen || quoteOnScreen ? " opacity-hide" : " opacity-show")}>
          <div className={"ss-paragraphs__sticky-child"}>
            <ImOnScreen setOnScreenState={[imageOnScreen, setImageOnScreen]} showLoader={false} />
            <h2 className={""}>Image</h2>
            <p className={""}>Accompanying these new compositions is a series of
              films taken from the settings that
              inspired the music. Footage filmed by Ben has been transformed in collaboration with
              immersive media artist Michaela French. These 360° videos are projected onto the inside of
              the dome, turning sound into sight to offer a real sensory experience.
              Michaela uses illustration and animation to illuminate the film that Ben collected, and thread it
              to the music. As a newcomer to the Dales, her fresh perspective on places Ben has known for
              a lifetime inspires her work, bringing the magic of these landscapes to life.</p>
          </div>
        </div>

        <div
          className={"ss-paragraphs__sticky-wrap ss-para-3 " + (quoteOnScreen ? " opacity-hide" : " opacity-show")}>
          <div className={"ss-paragraphs__sticky-child"}>
            <ImOnScreen setOnScreenState={[spaceOnScreen, setSpaceOnScreen]} showLoader={false} />
            <h2 className={""}>Space</h2>
            <p className={""}>The Soundscapes dome plays a leading role in the experience. Behind its creation is the
              expertise of Peter Dixon and his team from Northumbria University's architecture department.
              The curved interior allows the film to wrap around us, immersing us in these beautiful settings.
              The shape means sound rolls across surfaces to surround us with these soundscapes.
              There is also evidence to suggest that dome-shaped structures have positive psychological
              effects on us – they can create feelings of safety, community and harmony. Soundscapes’
              elements – sound, image and space – blend to engage the senses. The dome is part of the
              experience that transports us, connects us to the music, the imagery, and the places they
              come from.</p>
          </div>
        </div>

        <div className={"ss-paragraphs__sticky-wrap ss-para-4 " + (false ? " opacity-hide" : " opacity-show")}>
          <div className={"ss-paragraphs__sticky-child"}>
            <ImOnScreen setOnScreenState={[quoteOnScreen, setQuoteOnScreen]} showLoader={false} />
            <p className={""}><em>“It’s really about this extraordinary landscape, and it starts to answer the
              question ‘how do I see myself in relation to this place?’ It feels to me like there’s a sort of secret
              knowledge in people of all these places that I might never see. What Ben’s created and we’ve developed
              together, feels like a contemplation. Even if I haven’t been there, or seen it, this will bring it to my
              mind and allow me to feel it.”</em>
              <br />
              - Michaela French, artist
              <br /><br />
              Soundscapes is a joint venture with Skipton Town Hall and Northumbria University, and is
              funded by Arts Council England, Skipton Building Society and the Tarn Moor Estate.
            </p>
          </div>
        </div>


      </div>
    </>
  )
    ;
}

export default SoundscapesParagraphs;
