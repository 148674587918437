import React, {useContext, useEffect, useState} from "react";
import DonorForm_Pagination from "./DonorForm_Pagination";
import P1Container_Amount from "./donorForm_P1/P1Container_Amount";
import P2Container_PersonalDetails from "./donorForm_P2/P2Container_PersonalDetails";
import P3Container_StripeCheckout from "./donorForm_P3/P3Container_StripeCheckout";
import DonorForm_ConfirmationContainer from "./donorForm_Confirmation/DonorForm_ConfirmationContainer";
import {DonorFormContext} from "../../../providers/DonorFormContext";

function DonorFormContainer() {
  const {form} = useContext(DonorFormContext)
  const [pageNum, setPageNum] = useState(0);
  const [enableNextBtn, setEnableNextBtn] = useState(false);


  // DISABLE NEXT BTN WHEN PAGE DETAILS ARE INCOMPLETE
  useEffect(() => {
    let enableBtn = false
    if (pageNum === 0 && (form.selectedAmount >= 500 || form.customAmount >= 500)) enableBtn = true
    if (pageNum === 1 && (form.fName && form.lName && form.email && form.address.line1 && form.address.postal_code)) enableBtn = true
    if (pageNum === 2) enableBtn = true
    setEnableNextBtn(enableBtn)
  }, [pageNum, form])


  return (
    <div className={"donor-form"}>
      <DonorForm_Pagination totalPages={3} pageNum={pageNum} setPageNum={setPageNum} allowNext={enableNextBtn}>

        {pageNum === 0 ? <P1Container_Amount />
                       : pageNum === 1 ? <P2Container_PersonalDetails />
                                       : pageNum === 2 ? <P3Container_StripeCheckout setPageNum={setPageNum} />
                                                       : pageNum === 3 ?
                                                         <DonorForm_ConfirmationContainer setPageNum={setPageNum} />
                                                                       : null}


      </DonorForm_Pagination>
    </div>
  );
}

export default DonorFormContainer;