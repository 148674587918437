import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import ScreenContainer from "../components/blocks/ScreenContainer";
import {useLocation, useParams} from "react-router-dom";
import Event_PageContainer from "../components/events/Event_PageContainer";
import Event_NotesContainer from "../components/events/notes/Event_NotesContainer";
import BackButton from "../components/elements/BackButton";
import Event_NotesUnavailable from "../components/events/notes/Event_NotesUnavailable";
import FetchContent from "../dataManagement/FetchContent";

function EventPage() {
  const hashPath = useLocation().hash
  const {idAndName} = useParams()
  const [event, setEvent] = useState(null)
  const [renderNotes, setRenderNotes] = useState(false);
  const [notesAreOnline, setNotesAreOnline] = useState(false);

  const id = idAndName.split("-").shift()

  // Determine if "notes" || "notes unavailable" should be displayed
  useEffect(() => {
    setRenderNotes(hashPath.includes("notes"))
  }, [hashPath])

  console.log(event, id, moment().tz("Europe/London").format())
  // Determine if notes have expired or/and are online
  useEffect(() => {
    const notesOnline = event?.programmeNotes?.showNotes
    const useExpire = event?.programmeNotes?.useExpire
    const expired = event?.programmeNotes?.expireDate < moment().tz("Europe/London").format()
    const areOnline = useExpire ? (notesOnline && !expired) : notesOnline
    setNotesAreOnline(areOnline)
  }, [event])

  if (!event) return <FetchContent collection={"events"} docId={id} setData={setEvent} />
  return <ScreenContainer key={id}>

    {!renderNotes ? <BackButton /> :
     <BackButton text={"Return to event page"} className={"prime-colour"} to={"?return_to=whats-on"} />}

    <Event_PageContainer event={event} id={id} hide={renderNotes} notesAreOnline={notesAreOnline} />
    {notesAreOnline ?
     <Event_NotesContainer event={event} id={id} hide={!renderNotes} />
                    :
     <Event_NotesUnavailable event={event} hide={!renderNotes} />
    }

  </ScreenContainer>
}

export default EventPage;
