// REDUCER
import {eventsFetch, newsFetch, pastEventsFetch} from "./endlessScrollActions";

export const endlessScrollReducer = async (action) => {
  switch (action.type) {
    //Events
    case "events":
      return eventsFetch(action.startAfter)
    // Past Events
    case "pastEvents":
      return pastEventsFetch(action.startAfter)
    //News
    case "news":
      return newsFetch(action.startAfter)
    default:
      return []
  }
}
