// import firebase from "firebase/compat";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
  arrayUnion,
  arrayRemove,
  increment,
  deleteDoc
} from "firebase/firestore";
import {db} from "../firebase/firebaseConfig";

//// Get Doc(s) from db with query ////
export function getDocsByQuery(collectionName, queryObj, comparison = "==") {
  return new Promise(async function (resolve, reject) {
    let key = Object.keys(queryObj)[0]
    let dataArray = [];
    const q = query(collection(db, collectionName), where(key, comparison, queryObj[key]));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dataArray.push(doc)
    });
    resolve(dataArray)
  })
}


//get single doc from db - with doc ID
export const getDocById = (collection, docId) => new Promise((async (resolve, reject) => {
  const docRef = doc(db, collection, docId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists) {
    reject("No such document!")
  } else {
    resolve(docSnap.data())
  }
}));


//// Get data from db ////
export function getAllDocs(collectionName) {
  return new Promise(function (resolve, reject) {
    getDocs(collection(db, collectionName))
      .then((snapshot) => {
        let dataArray = [];
        snapshot.docs.forEach(doc => {
          dataArray.push(doc)
        })
        resolve(dataArray)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

//// Get doc data from DB (using getDocsByQuery) ////
export function getAllData(collection) {
  return new Promise((resolve, reject) => {
    getAllDocs(collection)
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })
      .catch(err => reject(err))
  })
}


//// Get doc data from DB (using getDocsByQuery) ////
export function getDataByQuery(collection, queryObj) {
  return new Promise((resolve) => {
    getDocsByQuery(collection, queryObj)
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })

  })
}


//// Get doc data from DB (using getDocsByQuery) ////
export function searchWithIdArray(collectionName, idArr) {
  return new Promise((resolve, reject) => {
    const ref = collection(db, collectionName);
    query(ref, where("id", "in", idArr))
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })
      .catch(err => reject(err))
  })
}


//// Post data to db ////
export function postData(collectionName, content) {
  return new Promise((resolve, reject) => {
    addDoc(collection(db, collectionName), content)
      .then((snapshot) => {
        resolve([true, snapshot.id])
      })
      .catch((err) => {
        reject([false, err])
      })
  })
}


//// Update data to db ////
//dataObj = Object of data to update. Key = value of query key. Value = value of data to update
//collectionName = name of collection to update
//keyToUpdate = key in doc to update
export function updateData(keyToUpdate, valueToUpdate, docId, collectionName) {
  return new Promise((resolve, reject) => {
    const ref = doc(db, collectionName, docId);
    updateDoc(ref, {[keyToUpdate]: valueToUpdate})
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}


//// Set data to db ////
export function setData(collectionName, docId, dataObj) {
  return new Promise((resolve, reject) => {
    setDoc(doc(db, collectionName, docId), dataObj)
      .then(res => {
        resolve([true, res])
      })
      .catch(reason => {
        reject([false, reason])
      })
  })
}

// Remove and add object to search
export const updateTitleInCollectionSearch = (collectionName, docId, oldTitle, newTitle) => {
  return new Promise(async (resolve, reject) => {
    const ref = doc(db, collectionName, "--collection-search--")
    await updateDoc(ref, {
      searchHelper: arrayRemove({id: docId, title: oldTitle})
    });
    await updateDoc(ref, {
      searchHelper: arrayUnion({id: docId, title: newTitle})
    })
    resolve([true, "_"])
  })
}


//// Set data to db and Increment count ////
export function setDocAndIncrement(collectionName, docId, dataObj, incrementBy = 1) {
  return new Promise(async (resolve, reject) => {
    const statsRef = doc(db, collectionName, "--collection-stats--")
    const searchRef = doc(db, collectionName, "--collection-search--")
    await updateDoc(statsRef, {
      docCount: increment(incrementBy)
    });
    await updateDoc(searchRef, {
      searchHelper: arrayUnion({id: docId, title: dataObj.title})
    });
    setData(collectionName, docId, dataObj)
      .then(res => resolve([true, res]))
      .catch(reason => {
        console.log("ERR: ", reason)
        reject([false, reason])
      })
  })
}

//// Delete data to db and decrement count ////
export function deleteDocAndDecrement(collectionName, docId, dataObj, decrementBy = -1) {
  return new Promise(async (resolve, reject) => {
    const statsRef = doc(db, collectionName, "--collection-stats--")
    const searchRef = doc(db, collectionName, "--collection-search--")
    await updateDoc(statsRef, {
      docCount: increment(decrementBy)
    });
    await updateDoc(searchRef, {
      searchHelper: arrayRemove({id: docId, title: dataObj.title})
    });
    deleteData(collectionName, docId)
      .then(res => resolve([true, res]))
      .catch(reason => {
        console.log("ERR: ", reason)
        reject([false, reason])
      })
  })
}


//// Delete data to db ////
export function deleteData(collectionName, docId) {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collectionName, docId))
      .then(() => resolve())
      .catch(err => reject(err))
  })
}
