import React, {useEffect, useState} from "react";

function RotatingImages({showImageNumber = 1}) {
  const [imgLoaded, setImgLoaded] = useState(false)


  return (
    <div className={"ss-image-container"}>

      <img className={(imgLoaded ? "ssParallax__img " : "") + (showImageNumber !== 1 && "opacity-hide")}
           style={{animationDelay: "-5s"}}
           src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fsoundscape%2FMahlam.png?alt=media&token=09f61fb2-daa5-49ed-bc7e-0eb2a7e423ef"}
           alt={"Soundscape - Mahlam"}
           onLoad={() => setImgLoaded(true)}
      />
      <img className={(imgLoaded ? "ssParallax__img " : "") + (showImageNumber !== 2 && "opacity-hide")}
           style={{animationDelay: "0"}}
           src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fsoundscape%2FYordas.png?alt=media&token=ac7a4137-c28c-447b-98a0-aaeb38942437"}
           alt={"Soundscape - Yordas"}
           onLoad={() => setImgLoaded(true)}
      />
      <img className={(imgLoaded ? "ssParallax__img " : "") + (showImageNumber !== 3 && "opacity-hide")}
           style={{animationDelay: "-10s"}}
           src={"https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/projects%2Fsoundscape%2FRiver%20day.png?alt=media&token=e74efadf-8be6-4733-b618-295cbc2485f3"}
           alt={"Soundscape - River Day"}
           onLoad={() => setImgLoaded(true)}
      />

    </div>
  );
}

export default RotatingImages;
