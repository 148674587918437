import React from 'react';
import HtmlReader from "../../components/blocks/HtmlReader";
import {decode} from "html-entities";

function Legal({content}) {
    return <>
        <div className="py-3 text-center text-md-left">
            <HtmlReader htmlContent={decode(content)} classNames={"legal-info"} editorId={"legal"} />
        </div>
    </>
}

export default Legal;