import React, {useEffect, useState} from "react";
import {getAllData, getDocById} from "./firebase/dbFunctions";
import Loader from "./components/Loader";

function FetchContent({collection, docId = null, setData, noLoader = false}) {
  useEffect(() => {
    if (docId) {
      console.log("FETCHING DATA WITH ID: ", docId)
      getDocById(collection, docId)
        .then(doc => {
          setData(doc)
        })
        .catch(err => console.log("ERR FETCHING CONTENT: ", err))
    } else {
      getAllData(collection)
        .then(docs => {
          setData(docs)
        })
        .catch(err => console.log("ERR FETCHING CONTENT: ", err))
    }
  }, [docId, collection])


  // Render
  // if (dataCollected) return <>{children}</>
  if (!noLoader) return <div />;
  return <div className="screen-container d-flex justify-content-center align-items-center">
    <Loader />
  </div>
}

export default FetchContent;
