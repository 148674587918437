import React from "react";
import {Link} from "react-router-dom";

const EventSurveyAndDonorBtns = ({event, position}) => {
  const notes = event[0].programmeNotes

  if (
    !notes.surveyBtnPositions.includes(`pos-${position}_survey`)
    && !notes.surveyBtnPositions.includes(`pos-${position}_donate`)
  ) return null

  return <div className={"hide-on-print"}>
    <p className={"mb-0 mt-5"}>{notes.surveyCallToActionText}</p>
    <div className={"d-flex"}>

      {notes.surveyBtnPositions.includes(`pos-${position}_survey`) &&
      <div className={""}>
        <Btn to={notes.surveyLink} keyName={"surveyLink"} notes={notes} textKeyName={"surveyBtnText"} />
      </div>}

      {notes.surveyBtnPositions.includes(`pos-${position}_donate`) &&
      <div className={"ml-3"}>
        <Btn to={notes.donateLink} keyName={"donateLink"} notes={notes} textKeyName={"donateBtnText"} />
      </div>}

    </div>
  </div>
}

export default EventSurveyAndDonorBtns;


const Btn = ({notes, keyName, textKeyName, to}) => {
  return <>
    {!to.includes("http") ?
     <Link to={`/${notes[keyName]}`} target={"_blank"} rel={"noreferrer"}>
       <button className={"header-btn--btn scbtn--2 "} type={"button"}
               onClickCapture={(e) => e.currentTarget.blur()}>{notes[textKeyName]}</button>
     </Link>
                          :
     <a href={notes[keyName]} target={"_blank"} rel={"noreferrer"}>
       <button className={"header-btn--btn scbtn--2 "} type={"button"}
               onClickCapture={(e) => e.currentTarget.blur()}>{notes[textKeyName]}</button>
     </a>}
  </>
}