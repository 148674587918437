import React from 'react';
import {Link} from "react-router-dom";
import {eventDateFormat} from "../../helper";
import {Row} from "react-bootstrap";

function HomeOneEventCard({cards}) {
    return <section className="container">
        <div id="topEvents" className="">
            <div className="sc-eventCardContainer">
                <Link to={`/whats-on/${cards[0].id}-${cards[0].title}`}>
                    <Row id="cardQuery" className="sc-homeEventCard card-side ml-0 mb-3">
                        <img src={cards[0].img} className="whats-on__img-home" alt={cards[0].alt} />
                        <div className="sc-cardText-wrap-side">
                            <h2><span>{cards[0].title}</span></h2>
                            <h3 className="sc-cardSub"><span>{cards[0].subTitle}</span></h3>
                            {cards[0].eventDates && cards[0].eventDates.map((date, i) => {
                                return <p key={i} className={"search-card__wo-date"}>{eventDateFormat(date)}</p>
                            })}
                            <p className=" sc-cardDetails whats-on__venue">{cards[0].venue}</p>
                            <p className=" whats-on__summary-home">{cards[0].summary}</p>
                        </div>
                    </Row>
                </Link>
            </div>
        </div>
    </section>
}

export default HomeOneEventCard;