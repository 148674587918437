import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faTwitter, faFacebookF, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

//todo: fix layout on small screens

function SocialShareBtns() {
    const [show, setShow] = useState(null)

    let url = window.location.href

    return <>
        <div className={"scSocial__share"}>
            {/*FACEBOOK Button*/}
            <div className={""}>
                <a id={"fbShareHref"} href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target={"_blank"}
                   className={"mb-auto mt-0"}
                   onMouseEnter={() => setShow("fb")}
                   onMouseLeave={() => setShow(null)}
                >
                    <FontAwesomeIcon icon={faFacebookF}/>
                </a>
            </div>
            {/*TWITTER Button*/}
            <div>
                <a id="twitterShareHref" href={`https://twitter.com/intent/tweet?url=${url}`} target={"_blank"}
                   onMouseEnter={() => setShow("tw")}
                   onMouseLeave={() => setShow(null)}
                >
                    <FontAwesomeIcon icon={faTwitter}/>
                </a>
            </div>
            {/*WHATSAPP Button*/}
            <div>
                <a id="whatsappShareHref"
                   href={`whatsapp://send?text=Take a look at this post from Skipton Camerata: ${url}`}
                   data-action="share/whatsapp/share"
                   onMouseEnter={() => setShow("wa")}
                   onMouseLeave={() => setShow(null)}
                >
                    <FontAwesomeIcon icon={faWhatsapp}/>
                </a>
            </div>
            {/*EMAIL Button*/}
            <div>
                <a id="mailShareHref"
                   href={`mailto:?subject=Skipton%20Camerata&body=Hello%2C%0A%0ATake%20a%20look%20at%20this%20post%20from%20Skipton%20Camerata%3A%0A%0A${url}`}
                   title={"Share by Email"}
                   onMouseEnter={() => setShow("mail")}
                   onMouseLeave={() => setShow(null)}
                >
                    <FontAwesomeIcon icon={faEnvelope} style={{cursor: "pointer"}}/>
                </a>
            </div>
        </div>
        {/*Social Share Text*/}
        <p className={"d-none d-lg-block line-height-tight m-0"}>&nbsp;{show === "fb" ? "Share this article on Facebook" :
            show === "mail" ? "Share this article by Email" :
                show === "tw" ? "Share this article on Twitter" :
                    show === "wa" ? "Share this article by WhatsApp" :
                        null
        }</p>
        <div className={"p-2 p-lg-0"}/>
    </>
}

export default SocialShareBtns;