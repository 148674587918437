import React, {useState} from "react";
import {createDonorFormData} from "../factoryFunctions/donorFormFactory";

// DATA THAT WILL COLLECTED FROM DB
const DB_DATA = {
  donorAmounts: [1000, 2500, 10000],
  friendAmounts: [1000, 2000, 5000],
}

// Donor Form Context
export const DonorFormContext = React.createContext({})

function DonorFormProvider({children}) {
  const [donorForm, setDonorForm] = useState(createDonorFormData());
  const [customer, setCustomer] = useState(null);


  // CONSTANTS
  const donorConstants = {
    MINIMUM_DONATION: 500,
    PAGE_TITLES: ["Donate", "Your Details", "Payment Details", "Donation Complete"]
  }


  // HANDLE DONOR FORM CHANGE
  const handleDonorFormChange = (val, key) => {
    // console.log("DONOR FORM CHANGE: \n", key, " : ", val)
    setDonorForm(prevState => ({...prevState, [key]: val}))
  }


  const formatAmount = (pennies) => {
    const res = pennies / Math.pow(10, 2)
    return res === 0 ? "" : res
  }

  return (
    <DonorFormContext.Provider value={{
      form: donorForm,
      dbData: DB_DATA,
      donorConstants,
      handleChange: handleDonorFormChange,
      formatAmount,
      customer,
      setCustomer
    }}>
      {children}
    </DonorFormContext.Provider>
  );
};

export default DonorFormProvider