import React from 'react';

function PageTopImg({show}) {
    return <>
        <div className={"page-top-container"}
             style={{
                 transition: ".4s",
                 opacity: show ? "1" : "0",
                 maxHeight: show ? "15.5rem" : "0",
                 overflow: "hidden"
             }}>
            <img className="top-img" src={"/images/libraries/general/pageTopIMG.png"}
                 alt={"Skipton Camerata Orchestra"}/>
            <div className="top-fade"/>
        </div>
    </>
}

// + (show ? "show-menu" : "hide-menu")

export default PageTopImg;