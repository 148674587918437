import React, {useState} from 'react';
import Masonry from "react-masonry-css";
import NewsCard from "../components/news/NewsCard";
import PageTitle from "../components/elements/PageTitle";
import MessageStrip from "../components/blocks/MessageStrip";
import ScreenContainer from "../components/blocks/ScreenContainer";
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";

function News() {
    const [posts, setPosts] = useState([])
    const breakpointColumnsObj = {
        default: 3,
        1700: 3,
        1180: 2,
        770: 1
    };

    return <ScreenContainer>
        <FetchWithEndlessScroll currentPageDocs={posts} setCurrentPageDocs={setPosts} type={"news"}>
            <PageTitle title={"News"}/>
            <MessageStrip docId={"05News"}/>
            {posts && posts[0] &&
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {posts.filter(p => p.online === true).map((post, i) => {
                    return <NewsCard key={i} card={post}/>
                })}

            </Masonry>}
        </FetchWithEndlessScroll>
    </ScreenContainer>
}

export default News;