import React, {useState} from "react";
import {decode} from "html-entities";
import FetchContent from "../../dataManagement/FetchContent";

function MessageStrip({docId, homeContent}) {
  const [content, setContent] = useState([])

  if (!content) return <FetchContent collection={"messageStrip"} docId={docId} setData={setContent} />
  return <Message content={homeContent && homeContent[0]} home={docId === "01Home"} />

}

export default MessageStrip;


// *** //


// MESSAGE COMPONENT
function Message({content, home}) {
  return <>
    {content && content.name && content.name !== "offline" ?
     <div id="welcome-message"
          className={"container data-from-editor " + (home ? "message-text-home" : "message-text-page")}>
       <p id="message-home" dangerouslySetInnerHTML={{__html: decode(content.message)}} />
     </div>
                                                           :
     <div className={"noMessage"} />
    }
  </>
}

